
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'

export const addHeaderAuthorization = config => {
  const loginToken = localStorage.getItem('loginToken')

  if (loginToken) {
    config.headers.Authorization = `Bearer ${loginToken}`
  }
}

export const handlerTokenError = () => {
  const token = localStorage.getItem('loginToken')
  if (token) {
    localStorage.removeItem('loginToken')
  }
  const path = router.history.current?.fullPath
  setTimeout(() => {
    if (router?.history?.current?.name !== 'Login') {
      store.dispatch('init')
      router.push({
        name: 'Login',
        query: { redirect: path }
      })
        .then(() => {
          store.dispatch('snackbar/showError', {
            content: i18n.t('api_errors.unauthorized'),
            timeout: 8000
          })
        })
    }
  }, 600)
}
