import { Tree } from './Tree'
import {
  Source,
  Node,
  LayerNode
} from '@/models'
import {
  setProperties,
  layerTypes,
  FILE_TYPE
} from '@/models/utils'

import { Map, LngLatBounds } from 'mapbox-gl'
export class LayerTree extends Tree {
  map
  constructor(uuid, config) {
    const { map, ...treeConfig } = config

    super(uuid, treeConfig)

    map && this.setMap(map)

    return this
  }

  // getter/setter

  // methods
  setMap(map) {
    if (!(map instanceof Map)) {
      console.debug('Cannot set map which is not Map instance')

      return this
    }

    setProperties(this, {
      map
    })

    return this
  }

  addNode(parent, node, beforeNode, fitBounds = true) {
    /**
     *  @param {Node} parent
     *  @param {Node} node
     *  @param {Node} [beforeNode=null]
     *  - 指定位置做insert，不指定就是append
     *  @returns self
    **/

    if (!(parent instanceof Node)) {
      console.debug('Cannot add node which target parent is not Node instance')

      return this
    }
    if (!(node instanceof Node)) {
      console.debug('Cannot add node which is not Node instance')

      return this
    }

    super.addNode(parent, node, beforeNode)

    if (node.source instanceof Source) {
      this.addSource(node.source.uuid, node.source.data)
    }

    this.updateLayerNodes(node, true, fitBounds)

    return this
  }

  removeNode(node) {
    /**
     *  @param {Node} node - 要被移除的node
     *  @returns self
    **/

    if (!(node instanceof Node)) {
      console.debug('Cannot remove node which is not Node instance')
      return this
    }

    super.removeNode(node)

    if (layerTypes.includes(node.type)) {
      this.removeLayer(node)

      return this
    }

    if (node instanceof LayerNode) {
      const layerNodes = this.getLeavesDF(node, LayerNode, layerNode => layerTypes.includes(layerNode.type))

      layerNodes.forEach(this.removeLayer.bind(this))
    }

    return this
  }

  moveNode(node, beforeNode) {
    /**
     *  NOTE: 目前限定只能在同個parent移動
     *  @param {Node} node - 要被移動的node
     *  @param {Node} [beforeNode=null] - 沒有beforeNode就是移到目標parent的TOP
     *  @returns self
    **/

    if (!(node?.parent instanceof Node)) {
      console.debug('Cannot move node which w/o parent')
      return this
    }

    super.moveNode(node.parent, node, beforeNode)

    this.updateLayerNodes(node, false)

    return this
  }

  hasSource(target) {
    const sourceId = target instanceof Source ? target.uuid : target

    return !!this.map.getSource(sourceId)
  }

  addSource(uuid, data) {
    /**
     *  @param {string} uuid
     *  @param {Object} data - source data
     *  {
     *    type: String,
     *    data: Object
     * }
     *  @returns self
    **/

    // const source = new Source(uuid, data)
    if (this.map.getSource(uuid)) {
      // this.map.removeSource(uuid)

      return this
    }

    this.map.addSource(uuid, data)

    return this
  }

  removeSource(target) {
    const sourceId = target instanceof Source ? target.uuid : target

    if (this.hasSource(sourceId)) {
      this.map.removeSource(sourceId)
    }

    return this
  }

  hasLayer(target) {
    const layerId = target instanceof LayerNode ? target.uuid : target

    return !!this.map.getLayer(layerId)
  }

  addLayer(layerNode, beforeId, fitBounds = true) {
    /**
     *  @param {LayerNode} layerNode - 新增到Mapbox的layerNode
     *  @param {string} [beforeId=null] - 指定圖層順序，不指定就是在TOP
     *  @returns self
    **/

    if (!(this.map instanceof Map)) {
      console.debug('Cannot add layer which w/o map instance')

      return this
    }

    this.map.addLayer(layerNode.toMapboxLayer(
      () => {
        if (!fitBounds) {
          if (layerNode.is3DFile) {
            layerNode.render()
          }

          return
        }

        this.fitBounds(layerNode.mapBoundingBox)
      }
    ), beforeId)

    return this
  }

  moveLayer(layerNode, beforeId) {
    /**
     *  @param {LayerNode} layerNode - 新增到Mapbox的layerNode
     *  @param {string} [beforeId=null] - 指定圖層順序，不指定就是在TOP
     *  @returns self
    **/
    if (!(this.map instanceof Map)) {
      console.debug('Cannot move layer which w/o map instance')

      return
    }

    this.map.moveLayer(layerNode.uuid, beforeId)

    return this
  }

  removeLayer(layerNode) {
    /**
     *  @param {LayerNode} layerNode - 新增到Mapbox的layerNode
     *  @returns self
    **/
    if (!(this.map instanceof Map)) {
      console.debug('Cannot remove layer which w/o map instance')

      return this
    }

    if (this.hasLayer(layerNode)) {
      this.map.removeLayer(layerNode.uuid)
    }

    if (this.hasSource(layerNode.sourceId)) {
      this.map.removeSource(layerNode.sourceId)
      layerNode.setSource(null)
    }

    return this
  }

  setMaplayerVisibility(layerNode, visibility) {
    this.map.setLayoutProperty(
      layerNode.uuid,
      'visibility',
      visibility || layerNode.visibility
    )
  }

  fitBounds(boundingBox, options = {}) {
    /**
     *  API ref: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#fitbounds
     *  @param {LngLatBounds} boundingBox - mapbox LngLatBounds instance
     *  @param {Object} [options={}] - mapbox fitBounds options
     *  @returns self
    **/

    if (!(boundingBox instanceof LngLatBounds)) {
      return this
    }

    const { padding = {}, ...resetOptions } = options

    this.map.fitBounds(
      boundingBox,
      {
        padding: {
          top: 160,
          bottom: 160,
          right: 100,
          left: 100,
          ...padding
        },
        ...resetOptions
      }
    )

    return this
  }

  toggleVisible(node, visible) {
    /**
     *  @param {Node} node
     *  @returns self
    **/

    if (!(node instanceof Node)) {
      console.debug('Cannot toggle node visible which is not Node instance')
      return this
    }

    node.toggleVisible(visible)

    this.getLeavesDF(node, LayerNode)
      .forEach(layerNode => this.setMaplayerVisibility(layerNode))
  }

  updateLayerNodes(insertedNode, isAdd = true, fitBounds = true) {
    /**
     * 1. 抓出新增Node底下所有的layerNodes
     * 2. 列出目前樹上所有的layerNodes
     * 3. beforeId為新增且最後一個的layerNode在所有layerNodes的下個鄰居
     *    若沒有下個鄰居就是TOP
     *  @param {Node} insertedNode - 在Tree新增在任一位置的Node
     *  @param {Boolean} isAdd - 用到的mapbox api (addLayer | moveLayer)
     *  @returns self
    **/

    if (!(insertedNode instanceof Node)) {
      console.debug('Cannot update mapbox layers which insertedNode is not Node instance')

      return this
    }

    // 要被更新的layerNodes
    const layerNodes = this.getLeavesDF(insertedNode, LayerNode, layerNode => layerTypes.includes(layerNode.type))

    if (!Array.isArray(layerNodes) || !layerNodes.length) {
      return this
    }

    // const latestLayer = layerNodes[layerNodes.length - 1]

    const treeLayerNodes = this.getLeavesDF(this.root, LayerNode)

    const index = treeLayerNodes.indexOf(layerNodes.slice().pop())

    const beforeId = index > -1
      ? treeLayerNodes[index + 1]?.uuid || null
      : null

    const fn = isAdd ? 'addLayer' : 'moveLayer'
    layerNodes.forEach(layerNode => this[fn](layerNode, beforeId, fitBounds))

    return this
  }

  playAnimation(node, processAnimation = true) {
    // reset frame index
    if (node.animation.index >= node.frameCount) {
      let resetFrameIndex = 0
      if (
        node.type === FILE_TYPE.MAPSET &&
        !!node.parent?.animation?.index // FOLDER frame index > 0
      ) {
        resetFrameIndex = node.parent.animation.index
      }

      node.animation.index = resetFrameIndex
    }

    // Folder
    if (node.type === FILE_TYPE.FOLDER) {
      const animatableMapsets = node.animatableMapsets

      // 為folder底下mapset執行playAnimation > node.play但不撥放
      animatableMapsets.forEach(animatableMapset => {
        this.playAnimation(animatableMapset, false)
      })

      if (animatableMapsets.length) {
        node.playAnimation(this)
      }

      return
    }

    // Mapset
    const layerNodes = node.children

    layerNodes.forEach(layerNode => {
      this.setMaplayerVisibility(layerNode, 'none')
    })

    node.playAnimation(this, processAnimation)
  }

  stopAnimation(node) {
    if (node.type === FILE_TYPE.FOLDER) {
      const animatableMapsets = node.animatableMapsets

      animatableMapsets.forEach(this.stopAnimation.bind(this))

      if (animatableMapsets.length) {
        node.stopAnimation(this)
      }

      return
    }

    node.stopAnimation(this)

    const layerNodes = node.children

    layerNodes.forEach(layerNode => {
      this.setMaplayerVisibility(layerNode)
    })
  }

  setAnimationFrame(node, frameIndex) {
    const newFrameIndex = frameIndex + 1

    node.animation.index = newFrameIndex

    node.setAnimationFrame(this, frameIndex)
  }

  pauseAnimation(node) {
    node.pauseAnimation()
  }
}
