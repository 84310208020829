import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.influx.measurements
const url = apiConfig.url
export const apiInfluxMeasurements = {
  get(influxBucketId, projectId) {
    // projectId is optional

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params: {
        parent_resource_uuid: influxBucketId,
        project_uuid: projectId
      }
    }

    return http.get(url, config)
  },
  post(data) {
    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }
    return http.post(url, data, config)
  },
  delete(data) {
    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials,
      data
    }

    return http.delete(url, config).then(res => res.data)
  }
}
