import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.s3.maplayer.metadata

export const apiS3MaplayerMetadata = {
  get(params) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params
    }

    return http.get(url, config)
  },
  put(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'put')

    const config = {
      withCredentials
    }

    return http.put(url, data, config)
  }
}
