import camelize from 'camelize'

import { apiCommonauthUsers, apiCommonauthTokens } from '@/api'

const state = () => ({
  user: null,
  isLoadingUser: false
})

const getters = {
  userName: state => {
    const email = state?.user?.username || ''

    const userName = email?.split?.('@')?.shift?.() || ''

    return userName
  }
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  fetchUser: ({ state, commit, dispatch }) => {
    commit('setState', {
      isLoadingUser: true
    })
    return apiCommonauthUsers.get()
      .then(async res => {
        const user = camelize(res?.data?.data)

        // user存在unexpired_token時,幫忙確認並更新token
        let isExpire = false
        if (user?.unexpiredToken) {
          isExpire = await apiCommonauthTokens
            .get(user.unexpiredToken)
            .then(() => false)
            .catch(() => true)
        }

        // 判斷state.user不存在,避免無窮fetch
        if (isExpire || (!state.user && user && !user.unexpiredToken)) {
          dispatch('createUnexpiredToken').then(() => dispatch('fetchUser'))
        }

        commit('setState', {
          user
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingUser: false
        })
      })
  },
  createUnexpiredToken: () => {
    return apiCommonauthTokens.put()
  }
  // updateUnexpiredToken: ({ dispatch }) => {
  //   return apiCommonauthTokens
  //     .delete()
  //     .then(() => dispatch('createUnexpiredToken'))
  // }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const user = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
