import { createDashboard } from '@/assets/js/grafana/model'

import { apiGrafanaDashboards } from '@/api'

// NOTE: Permission設定
// Porject owener創的dashboard, team權限參照project設定的grafana權限
// project grafana maintainer可以建立dashboard, maintainer自己會多一個resource_owner權限, 其他team是viewer

const state = () => ({
  formData: createDashboard(),
  currentEditDashboard: null // just for refrence, do not modify content fileds
})

const getters = {
  editModel: state => !!state.currentEditDashboard,
  isFileJoined: state => fileNode => state.formData?.maplayer_resource_uuid === fileNode?.uuid
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },

  setFormData: ({ commit }, payload) => {
    commit('setFormData', payload)
  },

  edit: ({ commit }, { dashboard } = {}) => {
    if (!dashboard) return

    commit('setState', {
      formData: JSON.parse(JSON.stringify(dashboard)),
      currentEditDashboard: dashboard
    })
  },

  clearCurrentDashboard: ({ commit }) => {
    commit('clearCurrentDashboard')
  },

  submit: ({ state, getters }, { project = {} } = {}) => {
    const { uuid: projectId, grafanaId } = project

    if (!projectId || !grafanaId) return

    let data = null

    const {
      resourceId,
      maplayer_resource_uuid: maplayerId,
      ...dashboard
    } = state.formData

    if (getters.editModel) {
      // edit dashboard
      data = {
        payload: {
          dashboard
        },
        maplayer_resource_uuid: maplayerId || null,
        resource_uuid: resourceId,
        project_uuid: projectId
      }

      return apiGrafanaDashboards.put(data)
    } else {
      // create dashboard
      data = {
        payload: {
          dashboard
        },
        maplayer_resource_uuid: maplayerId || null,
        parent_resource_uuid: grafanaId,
        project_uuid: projectId
      }

      return apiGrafanaDashboards.post(data)
    }
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setFormData: (state, payload) => {
    Object.assign(state.formData, payload)
  },
  clearCurrentDashboard: state => (state.currentEditDashboard = null)

}

export const form = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
