export const FILE_ICONS = {
  unknown: 'mdi-file-question-outline',
  'file-error': 'mdi-file-alert',
  'file-loading': 'mdi-loading',
  html: 'mdi-language-html5',
  js: 'mdi-nodejs',
  json: 'mdi-code-json',
  geojson: 'mdi-code-json',
  glb: 'mdi-video-3d',
  vtk: 'mdi-video-3d',
  zip: 'mdi-zip-box',
  tar: 'mdi-zip-box',
  md: 'mdi-language-markdown',
  pdf: 'mdi-file-pdf',
  png: 'mdi-file-image',
  txt: 'mdi-file-document-outline',
  xls: 'mdi-file-excel',
  dat: 'mdi-file-chart',
  folder: 'mdi-folder',
  'folder-open': 'mdi-folder-open',
  'folder-open-empty': 'mdi-folder-open-outline',
  'enter-folder': 'mdi-folder-move',
  'add-folder': 'mdi-folder-plus-outline',
  'joined-folder': 'mdi-folder-star',
  'joined-file': 'mdi-file-star',
  'settings-file': 'mdi-file-cog'
}
