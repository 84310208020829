import camelize from 'camelize'

import { apiCommonauthTeams, apiCommonauthUsers } from '@/api'

import { form } from './team-form'

const formatTeams = (baseTeams = {}) => {
  const { owner, member } = baseTeams
  const ownerTeams = (Array.isArray(owner) && owner) || []
  const memberTeams = (Array.isArray(member) && member) || []
  const teams = [
    ...ownerTeams.map(t => ({ ...t, permission: 'owner' })),
    ...memberTeams.map(t => ({ ...t, permission: 'member' }))
  ]

  return teams.map(team => {
    return {
      ...team,
      name: team.groupName,
      memberCount: team.members.length,
      members: formatMembers(team.members)
    }
  })
}

export const formatMembers = members => {
  if (!Array.isArray(members)) return []

  return members.map(member => {
    const email = member.username

    return {
      ...member,
      id: email,
      name: email?.split?.('@')?.[0] || '',
      email
    }
  })
}

const state = () => ({
  teams: [],
  members: [],
  isLoadingTeams: false,
  isLoadingMembers: false
})

const getters = {
  ownerTeams: state => state.teams.filter(t => t.permission === 'owner'),
  memberTeams: state => state.teams.filter(t => t.permission === 'member')
}

const actions = {
  init: ({ commit, dispatch }) => {
    dispatch('form/init')
    commit('setState', state())
  },

  setState: ({ commit }, payload) => {
    commit('setState', payload)
  },
  fetchTeams: ({ commit }) => {
    commit('setState', {
      isLoadingTeams: true
    })
    return apiCommonauthTeams.get()
      .then(res => {
        const teams = formatTeams(camelize(res?.data?.data))

        commit('setState', {
          teams
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingTeams: false
        })
      })
  },
  deleteTeam: (_, { team } = {}) => {
    if (!team) return

    const groupName = team.groupName
    return apiCommonauthTeams.delete(groupName)
  },
  fetchMembersByEmail: ({ commit }, { email = '' } = {}) => {
    commit('setState', {
      isLoadingMembers: true
    })
    return apiCommonauthUsers.post({
      username: email,
      query: 'fuzzy'
    })
      .then(res => {
        const members = formatMembers(camelize(res?.data?.data))
        commit('setState', {
          members
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingMembers: false
        })
      })
  },
  clearMembers: ({ commit }) => {
    commit('setState', {
      members: [],
      isLoadingMembers: false
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const teams = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    form
  }
}
