import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.commonauth.tokens

export const apiCommonauthTokens = {
  get(token) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    return http.get(url, config)
  },
  post(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  },
  put() {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'put')

    const config = {
      withCredentials
    }

    return http.put(url, null, config)
  },
  delete() {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials
    }

    return http.delete(url, config)
  }
}
