import { createTarget } from './target'

export const createOverride = target => ({
  matcher: {
    id: 'byFrameRefID',
    options: target.refId
  },
  properties: []
})

export const getDefaultPanelFieldConfig = (existTargets = []) => ({
  defaults: {
    color: {
      mode: 'palette-classic'
    },
    custom: {
      drawStyle: 'line', // line, bars, points
      fillOpacity: 10,
      gradientMode: 'none',
      barAlignment: 0,
      lineInterpolation: 'linear',
      lineWidth: 1,
      pointSize: 5,
      lineStyle: {
        fill: 'solid'
      },
      showPoints: 'never',
      spanNulls: false
    }
  },
  overrides: existTargets.map(createOverride)
})

export const newPanelIdByDashboard = dashboard => {
  // NOTE: Grafana 7.5.3目前測試Panel id為0時, snapshot會撈不到資料
  if (!dashboard) return
  // const oriPanels = dashboard?.panels || []
  const panelIds = dashboard?.panels
    ?.map?.(panel => Number(panel.id))
    ?.sort?.((a, b) => a - b)

  if (!Array.isArray(panelIds) || panelIds.length === 0) {
    return 1
  }

  if (panelIds[0] > 1) return 1

  if (panelIds.length === 1) {
    const [id] = panelIds

    return id + 1
  }

  const newId =
    panelIds.find((id, iId, arr) => {
      const nextId = arr[iId + 1]
      if (!nextId) return true
      return nextId - id > 1
    }) + 1

  return newId

  // return (
  //   (Array.isArray(oriPanels) &&
  //     oriPanels
  //       .map(p => Number(p.id))
  //       .sort()
  //       .pop() + 1) ||
  //   0
  // )
}

export const createPanel = (title = '', type = 'timeseries') => {
  const defaultTargets = [createTarget('Untitle Data 1')]

  return {
    title,
    type,
    datasource: '',
    targets: defaultTargets,
    fieldConfig: getDefaultPanelFieldConfig(defaultTargets),
    gridPos: {
      h: 8,
      w: 12,
      x: 0,
      y: 0
    },
    options: {
      legend: {
        calcs: [],
        displayMode: 'list',
        placement: 'bottom'
      },
      tooltip: {
        mode: 'single'
      }
    }
  }
}
