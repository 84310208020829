import { FileNode } from './FileNode'

import { Source } from '@/models'

import { setProperties, FILE_TYPE } from '@/models/utils'

export class LayerNode extends FileNode {
  type
  source
  layout={}
  paint={}
  editSimParamFileNode
  isCreatingMbDatFileNode = false
  mbDatFileNode // mabalance01 .dat的fileContent 有influx/outflux資料
  massbalanceStringline // Feature, FeatureCollection, or Geometry
  wsMassbalanceSlice
  mbSliceStagingFile
  animation={
    play: false,
    index: 0,
    frameNode: null,
    frameSource: null
  }

  constructor(uuid, config = {}) {
    // const { type, source, layout = {}, paint = {}, flyTo, ...fileNodeConfig } = config
    const { type, source, layout = {}, paint = {}, ...fileNodeConfig } = config

    super(uuid, fileNodeConfig)

    source && this.setSource(source)

    setProperties(this, {
      type: type ?? this.type,
      layout: layout ?? this.layout,
      paint: paint ?? this.paint
      // flyTo: flyTo ?? this.flyTo
    })
  }

  // getter/setter
  get sourceId() {
    return this.source?.uuid
  }

  get mapsetNode() {
    return this.parent
  }

  get folderNode() {
    return this.parent?.parent
  }

  get visibility() {
    /**
     * 根據自己和parent的visible, 找出是否要顯示, 提供對應值給mapbox
     * @returns {String}
    **/

    let visibility = this.visible ? 'visible' : 'none'
    let parent = this.parent
    while (parent) {
      if (!parent.visible) {
        visibility = 'none'
      }
      parent = parent.parent
    }

    return visibility
  }

  get legends() {
    const settings = this.stagingFile
      ? this.stagingFile.settings
      : this.settings

    const legends = settings?.plot?.legends

    return legends
  }

  get hasLegends() {
    return Array.isArray(this.legends) && this.legends.length
  }

  get mapBoundingBox() {
    return this?.source?.boundingBox
  }

  get animatableMapsets() {
    if (this.type !== FILE_TYPE.FOLDER) {
      return []
    }

    return this.children.filter(c => c.animatable)
  }

  get animatable() {
    if (!this.hasChildren) {
      return false
    }

    // FOLDER
    if (this.type === FILE_TYPE.FOLDER) {
      return this.children.some(c => c.animatable)
    }

    // MPASET
    const firstChild = this.firstChild

    const isSameClass = this.children.every(c => (c.constroucture === firstChild.constroucture))

    if (
      !isSameClass ||
      this.children.length === 1
    ) {
      return false
    }

    if (firstChild.is3DFile) {
      return true
    }

    return this.children.every(c => c.type === firstChild.type)
  }

  get isAnimationPlaying() {
    return this.animation.play
  }

  get is3DFrames() {
    return this.firstChild?.is3DFile
  }

  get frameCount() {
    if (this.type === FILE_TYPE.FOLDER) {
      return Math.max(...this.animatableMapsets.map(animatableMapset => animatableMapset.frameCount))
    }

    return this.children.length
  }

  // methods
  setSource(source) {
    /**
     * 允許source為null
     * @param {Source} [source=null]
     * @returns self
    **/

    if (source && !(source instanceof Source)) {
      console.debug('Cannot set source which is not Source instance')

      return this
    }

    setProperties(this, {
      source
    })

    return this
  }

  // toggleLegends() {
  //   this.setProperties({
  //     showLegends: !this.showLegends
  //   })

  //   return this
  // }

  toggleLegend(scalarsName) {
    const settings = this.stagingFile
      ? this.stagingFile.settings
      : this.settings

    const legend = settings?.plot?.legends?.find?.(legend => legend.scalarsName === scalarsName)

    if (legend) {
      setProperties(legend, {
        visible: !legend.visible
      })
    }

    return this
  }

  playAnimation(layerTree, processAnimation = true) {
    this.animation.play = true

    // prepare animation
    if (this.type === FILE_TYPE.MAPSET && !this.is3DFrames) {
      if (!this.animation.frameSource) {
        const frameSource = this.animation.frameSource = new Source(`frameSource-${this.uuid}`, this.firstChild.source)
        layerTree.addSource(frameSource.uuid, frameSource.data)
      }

      if (!this.animation.frameNode) {
        const frameNode = this.animation.frameNode = new LayerNode(`frameNode-${this.uuid}`, this.firstChild)

        frameNode.setSource(this.animation.frameSource)

        frameNode.toggleVisible(frameNode, true)
        layerTree.addLayer(frameNode, null, false)
      }
    }

    if (processAnimation) {
      this.processAnimation(layerTree)
    }
  }

  processAnimation(layerTree, frameIndex) {
    if (!this.animation.play) {
      return
    }

    // frameIndex 代表步階由外面控制
    const hasFrameIndex = typeof frameIndex === 'number'

    const curFrameIndex = hasFrameIndex
      ? frameIndex
      : this.animation.index

    if (curFrameIndex >= this.frameCount) {
      this.animation.play = false

      // Folder pause再play, 當下frame會被hide掉
      // 當mapset frameCount較小時, 要幫忙顯示最後一個
      if (this.type === FILE_TYPE.MAPSET) {
        this.setAnimationFrame(layerTree, this.frameCount - 1)
      }

      return
    }

    this.setAnimationFrame(layerTree, curFrameIndex)

    // this.animation.index++

    // Folder
    if (this.type === FILE_TYPE.FOLDER) {
      if (curFrameIndex < this.frameCount) {
        setTimeout(this.processAnimation.bind(this, layerTree), 1000)
      }
    }
    // Mapset
    if (this.type === FILE_TYPE.MAPSET) {
      if (!hasFrameIndex && curFrameIndex < this.frameCount) {
        setTimeout(this.processAnimation.bind(this, layerTree), 1000)
      }
    }
  }

  stopAnimation(layerTree) {
    this.resetAnimation(layerTree)
  }

  pauseAnimation() {
    this.animation.play = false
  }

  setAnimationFrame(layerTree, frameIndex) {
    // Folder
    if (this.type === FILE_TYPE.FOLDER) {
      this.animatableMapsets.forEach(animatableMapset => {
        animatableMapset.animation.play = true
        animatableMapset.processAnimation(layerTree, frameIndex)
      })

      this.animation.index = frameIndex + 1

      return
    }

    // Mapset
    if (!this.is3DFrames) {
      // geojson播放方式
      const frameSource = this.animation.frameSource
      const nextSource = this.children[frameIndex].source
      const mbxFrameSource = layerTree.map.getSource(frameSource.uuid)

      mbxFrameSource.setData(nextSource.geoJsonData)
    } else {
      // glb播放方式
      const prevFrameIndex = this.animation.index
      if (prevFrameIndex) { // prevFrameIndex 0 代表沒撥放過
        const prevNode = this.children[prevFrameIndex - 1]
        layerTree.toggleVisible(prevNode, false)
      }

      const curNode = this.children[frameIndex]
      layerTree.toggleVisible(curNode, true)
    }

    this.animation.index = frameIndex + 1
  }

  resetAnimation(layerTree) {
    if (this.type === FILE_TYPE.MAPSET && !this.is3DFrames) {
      const frameNode = this.animation.frameNode
      const frameSource = this.animation.frameSource

      if (frameNode) {
        frameNode.setSource(null)
        layerTree.removeLayer(frameNode)
      }
      if (frameSource) {
        layerTree.removeSource(frameSource)
      }
    }

    this.animation = {
      play: false,
      index: 0,
      frameNode: null,
      frameSource: null
    }
  }

  toMapboxLayer(callback) {
    /**
     * Format成MapboxLayer
     * ref: https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
     * @returns mapboxLayer
    **/

    callback()

    return {
      id: this.uuid,
      type: this.type,
      source: this.source?.uuid,
      layout: {
        ...this.layout,
        visibility: this.visibility
      },
      paint: this.paint
    }
  }
}
