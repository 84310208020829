import { form } from './panel-form'

import { formatQuery, formatSnapshotData } from '@/assets/js/grafana/model'

import {
  apiGrafanaGrafanas,
  apiGrafanaDashboards,
  apiGrafanaSnapshots,
  apiGrafanaQueries
} from '@/api'

import i18n from '@/i18n'

const state = () => ({
  grafanaDatasourceId: null, // Number
  isLoadingDatasourceId: false
})

const getters = {}

const actions = {
  init: ({ commit, dispatch }) => {
    dispatch('form/init')
    commit('setState', state())
  },

  deletePanel: (_, { dashboard: oriDashboard, panel, projectId } = {}) => {
    const { resourceId, maplayer_resource_uuid: maplayerResourceUuid, ...dashboard } = oriDashboard
    const formData = {
      payload: {
        dashboard: {
          ...dashboard,
          panels: dashboard.panels.filter(p => p.id !== panel.id)
        }
      },
      maplayer_resource_uuid: maplayerResourceUuid,
      resource_uuid: resourceId,
      project_uuid: projectId
    }

    return apiGrafanaDashboards.put(formData)
  },
  fetchGrafanaDatasourceId: ({ commit }, { grafanaId, projectId } = {}) => {
    if (!grafanaId) return

    commit('setState', {
      isLoadingDatasourceId: grafanaId
    })

    return apiGrafanaGrafanas.get(grafanaId, projectId)
      .then(res => {
        const grafanaDatasourceId = res?.data?.data?.datasource_id

        commit('setState', { grafanaDatasourceId })

        return grafanaDatasourceId
      })
      .finally(() => {
        commit('setState', {
          isLoadingDatasourceId: false
        })
      })
  },
  fetchSnapshotUrl: async (
    { dispatch },
    {
      panel, dashboard, time,
      useFeatureProperties = false, dashboardOptions = {}
    } = {}
  ) => {
    // useFeatureProperties為false或feature的properties(Object)
    if (!panel || !dashboard) return

    // fetch snapshotData
    const snapshotData = await dispatch('fetchSnapshotData', {
      panel,
      useFeatureProperties,
      time
    }).catch(error => error)

    if (snapshotData instanceof Error) {
      return Promise.reject(snapshotData)
    }

    // set snapshot time range
    let timeRange = Array.isArray(time)
      ? Object.fromEntries([
        ['from', new Date(time[0]).toISOString()],
        ['to', new Date(time[1]).toISOString()]
      ])
      : null
    if (!timeRange) {
      // from 抓所有數據最早的點
      // to 抓現在
      let from = snapshotData
        .map(data => {
          const [start] =
            data?.fields?.find?.(f => f.type === 'time')?.values || []

          return start
        })
        .filter(Boolean)
        .sort()
        .shift()
      if (!from) {
        // default from a year ago
        const aYearAgo = new Date()
        aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)

        from = +aYearAgo
      }
      // End set snapshot time range
      timeRange = {
        from: new Date(from).toISOString(),
        to: new Date().toISOString()
        // to: new Date('2021-12-31T16:00:00.000Z')
      }
    }

    if (!Array.isArray(snapshotData)) {
      return Promise.reject(snapshotData)
    }

    const formData = {
      dashboard: {
        ...dashboard,
        // annotations: {
        //   list: [
        //     {
        //       builtIn: 1,
        //       datasource: '-- Grafana --',
        //       enable: true,
        //       hide: true,
        //       iconColor: 'rgba(0, 211, 255, 1)',
        //       name: 'Annotations & Alerts',
        //       target: {
        //         limit: 100,
        //         matchAny: false,
        //         tags: [],
        //         type: 'dashboard'
        //       },
        //       type: 'dashboard'
        //     }
        //   ]
        // },
        // links: [],
        // liveNow: false,
        // weekStart: '',
        // fiscalYearStartMonth: 0,
        // style: 'dark',
        tags: [],
        // schemaVersion: 36,
        // refresh: '',
        // editable: true,
        // graphTooltip: 0,
        templating: {
          list: []
        },
        timepicker: {},
        // snapshot: {
        //   timestamp: new Date().toISOString()
        // },
        time: {
          ...timeRange
          // raw: {
          //   ...time
          // }
        },
        panels: [
          {
            ...panel,
            type: 'timeseries',
            snapshotData
            // timeFrom: null,
            // timeShift: null,
            // pluginVersion: '8.3.3'
          }
        ],
        ...dashboardOptions
      },
      panel_id: panel.id
    }

    return apiGrafanaSnapshots.post(formData).then(res => {
      const snapshotUrl = res?.data?.data?.url

      return snapshotUrl || ''
    })
  },
  fetchSnapshotData: async (
    { state, dispatch },
    {
      panel, time = [],
      useFeatureProperties = false, aggregateWindow = ''
    } = {}
  ) => {
    // useFeatureProperties為false或feature的properties(Object)
    if (state.grafanaDatasourceId == null) {
      return Promise.reject(new Error('Miss datasource id'))
    }

    if (!panel) return

    const timeRange = time.map(t => (t && new Date(t).toISOString()) || null)
    const [from, to] = timeRange

    const queries = panel.targets.map(target => {
      const datasource = typeof panel.datasource === 'string'
        ? {
            type: 'influxdb',
            uid: panel.datasource
          }
        : panel.datasource

      return {
      // query: t.query,
        datasource,
        measurement: target.measurement,
        intervalMs: 86400000,
        query: formatQuery({
          influxBucketName: panel.datasource?.influxBucketName || panel.datasource || '',
          target,
          useFeatureProperties,
          aggregateWindow,
          start: from || '-20y',
          stop: to || 'now()'
        }),
        refId: target.refId,
        datasourceId: state.grafanaDatasourceId,
        maxDataPoints: 500
      }
    })
    const data = {
      ...timeRange,
      // from: '1136044800000',
      // to: '1640966400000',
      // range: {
      //   from: '2005-12-31T16:00:00.000Z',
      //   to: '2021-12-31T16:00:00.000Z',
      //   raw: {
      //     from: '2005-12-31T16:00:00.000Z',
      //     to: '2021-12-31T16:00:00.000Z'
      //   }
      // },

      queries
    }

    return apiGrafanaQueries
      .post({ data })
      .then(res => {
        const isDataTooLarge = res.data?.data?.is_data_too_large
        const dataResult = res?.data?.data?.data_result
        const results = Object.entries(dataResult?.results || {})

        const regTooManyData = [
          /Client.Timeout or context cancellation while reading body/,
          /A query returned too many datapoints and the results have been truncated/
        ]

        if (
          isDataTooLarge ||
          results.some(
            result => regTooManyData.some(
              reg => reg.test(result[1].error)
            )
          )
        ) {
          const everyItems = ['1d', '1w', '1mo', '3mo', '6mo', '1y']
          let every = '1d'

          everyItems.some((eve, iEve) => {
            if (aggregateWindow?.includes(eve)) {
              every = everyItems[iEve + 1]

              return true
            }

            return false
          })

          if (!every) {
            return Promise.reject(new Error(i18n.t('page.panels.data_too_much')))
          }

          return dispatch('fetchSnapshotData', {
            aggregateWindow: `every: ${every}, fn: mean, createEmpty: false`,
            panel,
            useFeatureProperties
          })
        }

        return formatSnapshotData(results, panel.fieldConfig)
      })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const panels = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    form
  }
}
