import { WsSave } from '@/websocket'
import PQueue from 'p-queue/dist' // esm lib要from 'xxx/dist'
import i18n from '@/i18n'

import {
  apiS3StagingFiles,
  apiS3StagingfileData,
  apiS3StagingfilePromotion
} from '@/api'

import { SETTINGS_KEYS } from '@/models/utils'

import * as modules from './modules'

const { PARENT_VTK, BOUNDING_BOX_VTK } = SETTINGS_KEYS

const state = () => ({
  resetStaging: true
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  save: async ({ dispatch }, {
    project,
    plotSettings, // 針對勾選的mesh做新的plot設定
    glbFileName,
    fileNode, // glb file node
    layerNode, // layer node to check stagingFiles
    settings
  } = {}) => {
    if (
      !Array.isArray(plotSettings) ||
      !glbFileName
    ) {
      return
    }

    // 建立新glb maplayer
    const mapset = fileNode.parent
    const glbFileNode = await dispatch('files/createMaplayer', {
      project,
      parent: mapset,
      maplayerName: `${glbFileName}.glb`,
      settings: {
        ...settings,
        [BOUNDING_BOX_VTK.key]: settings[BOUNDING_BOX_VTK.key] ||
          layerNode.sourceMesh.settings[PARENT_VTK.key]
      }
    }, { root: true })
      .catch(error => {
        return Promise.reject(error)
      })

    if (glbFileNode instanceof Error) {
      return Promise.reject(glbFileNode)
    }

    // 提升vtk meshes
    const saveMeshes = settings.meshes.map(mesh => mesh.name)
    const vtkStagingMeshes = layerNode.stagingMeshes
      .filter(mesh => saveMeshes.includes(mesh.id))
      .filter(mesh => !mesh.promoted)

    if (vtkStagingMeshes.length) {
      await dispatch('promoteVtkStagingFiles', {
        project,
        parent: mapset,
        vtkStagingMeshes,
        settings: {
          ...settings,
          [BOUNDING_BOX_VTK.key]: settings[BOUNDING_BOX_VTK.key] ||
          layerNode.sourceMesh.settings[PARENT_VTK.key]
        }
      })
        .then(() => {
          dispatch('files/fetchFileNodes', {
            project,
            parent: mapset,
            loading: false
          }, { root: true })
          dispatch('projects/fetchPermissions', {
            projectId: project.uuid
          }, { root: true })
        })
        .catch(error => {
          dispatch('files/deleteFile', {
            project,
            fileNode: glbFileNode
          }, { root: true })

          return Promise.reject(error)
        })
    }

    const wsSave = new WsSave()

    const boundingBoxVtkResourceUuid = settings[BOUNDING_BOX_VTK.key] ||
    layerNode.sourceMesh.settings[PARENT_VTK.key]

    wsSave.start({
      project_uuid: project.uuid,
      parent_resource_uuid: mapset.uuid,
      glb_resource_uuid: glbFileNode.uuid,
      bounding_box_vtk_resource_uuid: settings.meshes.some(mesh => mesh?.parentVtk === boundingBoxVtkResourceUuid)
        ? null
        : boundingBoxVtkResourceUuid,
      plot_settings: plotSettings
    })
    glbFileNode.setProperties({
      isLoading: true,
      isUploading: true
    })
    return new Promise((resolve, reject) => {
      wsSave.$on(wsSave.EVENT.ENDED, () => {
        dispatch('files/fetchFileNodes', {
          project,
          parent: mapset,
          loading: false
        }, { root: true })
      })
      wsSave.$on(wsSave.TASK.SUCCESS, async () => {
        const fileNodeWithContent = await dispatch('files/fetchFileContent', {
          project,
          fileNode: glbFileNode
        }, { root: true })
          .then(fileNodeWithContent => {
            glbFileNode.setProperties({
              isLoading: false
            })

            return fileNodeWithContent
          })
          .catch(error => {
            wsSave.dispatchEvent(wsSave.EVENT.ERROR, error)
          })

        if (fileNodeWithContent) {
          resolve(fileNodeWithContent)
        }
      })
      wsSave.$on(wsSave.EVENT.ERROR, error => {
        glbFileNode.setProperties({
          isLoading: i18n.t('load_faild'),
          isUploading: false
        })

        dispatch('files/deleteFile', {
          project,
          fileNode: glbFileNode
        }, { root: true })

        reject(error)
      })
    })
  },
  promoteVtkStagingFiles: ({ dispatch }, { project, vtkStagingMeshes, parent, settings } = {}) => {
    return new Promise((resolve, reject) => {
      const queue = new PQueue({ concurrency: 1 })
      let error = null
      queue.on('error', err => {
        error = err
      })
      queue.on('idle', () => {
        setTimeout(() => {
          if (error instanceof Error) {
            return reject(error)
          }

          resolve()
        }, 1000)
      })
      queue.addAll(
        vtkStagingMeshes.map(mesh => {
          return () => dispatch('promoteStagingFile', {
            project,
            parent,
            stagingFileUuid: mesh.settings.parentVtk,
            maplayerName: `${mesh.id}.vtk`,
            settings: {
              ...settings,
              meshes: [
                settings.meshes.find(settingsMesh => settingsMesh.name === mesh.id)
              ]
            }
          })
            .then(() => { mesh.promoted = true })
        })
      )
    })
  },
  fetchStagingFileContent: (_, { project, stagingFileUuid, responseType = 'blob' } = {}) => {
    return apiS3StagingfileData.get({
      project_uuid: project.uuid,
      resource_uuid: stagingFileUuid
    }, responseType)
  },
  createStagingFile: (_, { project, glbLayerNode } = {}) => {
    return apiS3StagingFiles.post({
      project_uuid: project.uuid,
      parent_resource_uuid: glbLayerNode.parent.uuid
    })
      .then(res => {
        const stagingFileUuid = res.data.data.resource.uuid

        // glbLayerNode.setProperties({
        //   stagingFileUuid
        // })

        return stagingFileUuid
      })
  },
  promoteStagingFile: (_, {
    project,
    parent,
    stagingFileUuid,
    maplayerName,
    settings = {}
  } = {}) => {
    return apiS3StagingfilePromotion.post({
      project_uuid: project.uuid,
      parent_resource_uuid: parent.uuid,
      resource_uuid: stagingFileUuid,
      maplayer_name: maplayerName,
      settings
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const postprocess = {
  namespaced: true,
  modules,
  getters,
  state,
  mutations,
  actions
}
