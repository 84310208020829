import Vue from 'vue'
import camelize from 'camelize'
import settings from '@/assets/js/settings.js'

import EventWebSocket from '@/websocket/EventWebSocket'

const wsBaseUrl = settings.WS_ENDPOINT
const url = `${wsBaseUrl}/ws/postprocessing/slice`

const EVENT_MAP = {
  OPEN: 'open',
  CHECK_PERMISSION: 'check_permission',
  PERMISSION_CHECKED: 'permission_checked',
  RESET_STAGING: 'reset_staging_area',
  STAGING_RESET: 'staging_area_reset',
  START: 'start_job',
  STARTED: 'job_started',
  CHECK_STATUS: 'check_status',
  STATUS_CHECKED: 'status_checked',
  END: 'end_job',
  ENDED: 'job_ended',
  ERROR: 'error'
}

const TASK_STATUS_MAP = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

const data = () => ({
  ws: null,
  data: null, // {
  //   project_uuid: String,
  //   parent_resource_uuid: String, // mapset resource uuid
  //   vtk_mesh_pairs: [
  //     {
  //       mesh_uuid: String,
  //       vtk_resource_uuid: String
  //     }
  //   ],
  //   glb_resource_uuid: String,
  //   glb_staging_file_resource_uuid: String,
  //   active_scalars_name: String,
  //   colormap_name: String,
  //   colormap_level: String,
  //   range_min: String,
  //   range_max: String
  // }
  resetStaging: false,
  countVtkStagingFiles: 3,
  createStagingFile: null,
  taskUuid: null,
  EVENT: EVENT_MAP,
  TASK: TASK_STATUS_MAP,
  delayCheck: 500, // ms,
  errorMessage: '',
  permissions: {
    vtk: false,
    glb: false
  }
})

const computed = {
  isValidPermission() {
    return Object.values(this.permissions).every(Boolean)
  }
}

const beforeDestroy = function() {
  if (this.ws?.readyState === 1) {
    this.close()
  }
}

const methods = {
  send(eventName, data) {
    if (this.ws?.readyState !== EventWebSocket.OPEN) {
      return
    }

    this.ws.send(eventName, data)
  },
  start(data) {
    // data = {
    //   resetStaging: Boolean,
    //   createStagingFile: Function, // return stagingFileUuid,
    //   project_uuid: String,
    //   parent_resource_uuid: String, // mapset resource uuid
    //   vtk_mesh_pairs: [
    //     {
    //       mesh_uuid: null | String, // null 會創新的mesh
    //       vtk_resource_uuid: String
    //     },
    //     ...
    //   ],
    //   vtk_resource_uuid: String,
    //   glb_resource_uuid: String,
    //   glb_staging_file_resource_uuid: String,
    //   origin: Array, // [x, y, z] Babylonjs原始座標的slice參考點 (前端位移之前的slice參考點座標)
    //   countVtkStagingFiles: Number, // 切片面的個數, 讓resetStaging時可以創對應的切平面, 預設3
    //   active_scalars_name: String,
    //   colormap_name: String,
    //   colormap_level: String,
    //   range_min: Number | null,
    //   range_max: Number | null
    // }

    this.data = data

    if (data.resetStaging) {
      this.resetStaging = data.resetStaging
    }
    delete this.data.resetStaging

    if (data.createStagingFile) {
      this.createStagingFile = data.createStagingFile
    }
    delete this.data.createStagingFile

    if (data.countVtkStagingFiles != null && data.countVtkStagingFiles !== '') {
      this.countVtkStagingFiles = data.countVtkStagingFiles
    }
    delete this.data.countVtkStagingFiles

    this.connect()
  },
  connect() {
    this.ws = new EventWebSocket(url)

    this.ws.addEventListener(this.EVENT.OPEN, this.onOpen)

    this.ws.addEventListener('message', e => {
      const versionUuid = e?.data?.version_uuid
      if (versionUuid) {
        this.data.version_uuid = versionUuid
      }
    })

    this.ws.addEventListener(this.EVENT.PERMISSION_CHECKED, this.onPermissionChecked)

    // STAGING_RESET後要先創staging file resource
    if (this.resetStaging && this.createStagingFile) {
      this.ws.addEventListener(this.EVENT.STAGING_RESET, async () => {
        this.data.glb_staging_file_resource_uuid = await this.createStagingFile().catch(error => {
          this.onError(error)
          this.close()
        })
        const vtkStagingFileUuids = Array(this.countVtkStagingFiles).fill(null)

        await Promise.all(
          vtkStagingFileUuids.map((_, iVtk) => this.createStagingFile()
            .then(stagingFileUuid => { vtkStagingFileUuids[iVtk] = stagingFileUuid })
          )
        )
          .catch(error => {
            this.onError(error)
            this.close()
          })

        this.data.vtk_mesh_pairs = [
          ...this.data.vtk_mesh_pairs,
          ...vtkStagingFileUuids.map(vtkStagingFileUuid => ({
            mesh_uuid: null,
            vtk_resource_uuid: vtkStagingFileUuid
          }))
        ]
      })
    }
    this.ws.addEventListener(this.EVENT.STAGING_RESET, this.onStagingReset)

    // Trigger check task after job started
    this.ws.addEventListener(this.EVENT.STARTED, this.onStarted)
    // Task status handler
    this.ws.addEventListener(this.EVENT.STATUS_CHECKED, this.onStatusChecked)
    // Job ended handler
    this.ws.addEventListener(this.EVENT.ENDED, this.onEnded)

    this.ws.addEventListener(this.EVENT.ERROR, this.onError)
    this.ws.onclose = this.onClose
  },
  sendCheckPermission(permissionName, resourceUuid) {
    const data = {
      permission_name: permissionName,
      resource_uuid: resourceUuid,
      project_uuid: this.data.project_uuid
      // parent_resource_uuid: this.data.project_uuid,
      // version_uuid: this.data.version_uuid
    }

    if (this.data.version_uuid) {
      data.version_uuid = this.data.version_uuid
    }

    this.send(this.EVENT.CHECK_PERMISSION, data)
  },
  sendResetStaging() {
    this.send(this.EVENT.RESET_STAGING, {
      version_uuid: this.data.version_uuid
    })
  },
  sendStartJob() {
    const colormapLevel = parseInt(this.data.colormap_level)
    const rangeMin = parseFloat(this.data.range_min)
    const rangeMax = parseFloat(this.data.range_max)

    this.send(this.EVENT.START, {
      version_uuid: this.data.version_uuid,
      glb_staging_file_resource_uuid: this.data.glb_staging_file_resource_uuid,
      origin: this.data.origin,
      merge: this.data.merge,
      plot_setting: {
        active_scalars_name: this.data.active_scalars_name,
        colormap_name: this.data.colormap_name,
        colormap_level: colormapLevel,
        vtk_mesh_pairs: this.data.vtk_mesh_pairs,
        range_min: Number.isNaN(rangeMin) ? null : rangeMin, // 沒值的話要null才送得出去, 不能是undefined
        range_max: Number.isNaN(rangeMax) ? null : rangeMax // 沒值的話要null才送得出去, 不能是undefined
      }
    })
  },
  sendCheckStatus() {
    this.send(this.EVENT.CHECK_STATUS, {
      task_uuid: this.taskUuid,
      version_uuid: this.data.version_uuid
    })
  },
  sendEndJob() {
    this.send(this.EVENT.END, {
      version_uuid: this.data.version_uuid
    })
  },
  onOpen(e) {
    if (!this.data) {
      return
    }

    if (this.taskUuid) {
      this.sendCheckStatus()

      return
    }

    // create a job task
    // this.sendStartJob()

    this.sendCheckPermission('vtk', this.data.vtk_resource_uuid)
    // this.sendCheckPermission('glb', this.data.glb_resource_uuid)
  },
  onPermissionChecked(e) {
    const permissionName = e.data.permission_name

    // resource permission checked
    if (
      permissionName &&
      Object.prototype.hasOwnProperty.call(this.permissions, permissionName)
    ) {
      this.permissions[permissionName] = true
    }

    if (!this.isValidPermission) {
      this.sendCheckPermission('glb', this.data.glb_resource_uuid)

      return
    }

    // 所有permission都pass就start job
    // this.sendResetStaging()
    this.resetStaging
      ? this.sendResetStaging()
      : this.sendStartJob()
  },
  async onStagingReset(e) {
    this.$emit(this.EVENT.STAGING_RESET, camelize(e.data))

    if (!this.data.glb_staging_file_resource_uuid) {
      this.onError(new Error('缺少必要參數'))
      this.close()

      return
    }

    this.sendStartJob()
  },
  onStarted(e) {
    this.$emit(this.EVENT.STARTED, e.data)

    this.taskUuid = e.data.task_uuid

    // Trigger check process
    this.sendCheckStatus()
  },
  onStatusChecked(e) {
    this.$emit(this.EVENT.STATUS_CHECKED, camelize(e.data))

    const status = e.data.status

    // Success case
    if (status === this.TASK.SUCCESS) {
      this.$emit(this.TASK.SUCCESS, camelize(e.data))

      this.sendEndJob()
      return
    }

    // Failure case
    if (status === this.TASK.FAILURE) {
      this.onError(new Error('發生未知的錯誤'))
      return
    }

    // Pedding case
    setTimeout(() => {
      this.sendCheckStatus()
    }, this.delayCheck)
  },
  onEnded(e) {
    this.$emit(this.EVENT.ENDED, e.data)

    this.close()

    this.$destroy()
  },
  onError(e) {
    const error = e instanceof Error ? e : new Error('Websocket連線發生錯誤')
    this.$emit(this.EVENT.ERROR, error)

    this.$destroy()
  },
  onClose(e) {
    if (e.code !== 1000 && e.code) {
      console.debug(e)
      this.$emit(this.EVENT.ERROR, new Error(`連線發生錯誤，錯誤代碼:${e.code}`))
    }
  },
  close() {
    this.ws.close()
  }
}

export class WsSlice extends Vue {
  constructor() {
    super({
      data,
      computed,
      beforeDestroy,
      methods
    })
  }
}
