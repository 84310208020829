import { setProperties } from '@/models/utils'

export class Node {
  uuid
  parent=null
  visible = true
  children = []

  constructor(uuid, { parent, visible } = {}) {
    parent && this.setParent(parent)

    setProperties(this, {
      uuid,
      visible: visible ?? this.visible
    })
  }

  // getter/setter
  get root() {
    if (!this.parent) return this

    let root = this.parent
    while (root.parent) {
      root = root.parent
    }

    return root
  }

  get level() {
    if (!this.parent) return 0

    return this.parent.level + 1
  }

  get index() {
    // 處在parent.children的index
    if (!this.parent) {
      console.debug('Cannot get index which w/o parent')
      return -1
    }

    return this.parent.children.indexOf(this)
  }

  get hasChildren() {
    return this.children?.length > 0
  }

  get firstChild() {
    return this.children?.[0]
  }

  // methods
  setProperties(fileds) {
    setProperties(this, fileds)
  }

  setParent(parent) {
    /**
     * 允許parent為null
     * @param {Node} [parent=null]
     * @returns self
    **/

    if (parent && !(parent instanceof Node)) {
      console.debug('Cannot set parent which is not Node instance')
      return this
    }

    setProperties(this, {
      parent: parent ?? null
    })

    return this
  }

  toggleVisible(visible) {
    /**
     * @returns self
    **/

    setProperties(this, {
      visible: visible ?? !this.visible
    })

    return this
  }
}
