export const createDashboard = title => {
  // return {
  //   dashboard:

  return {
    title,
    description: '',
    maplayer_resource_uuid: null,
    // annotations: {
    //   list: []
    // },
    // editable: true,
    // gnetId: null,
    // graphTooltip: 0,
    // links: [],
    panels: [],
    // refresh: false,
    // style: 'light',
    // tags: [],
    // templating: {
    //   list: [
    //     {
    //       current: {
    //         selected: false,
    //         text: 'test123',
    //         value: 'test123'
    //       },
    //       description: null,
    //       error: null,
    //       hide: 0,
    //       label: null,
    //       name: 'testaaa123',
    //       options: [
    //         {
    //           selected: true,
    //           text: 'test123',
    //           value: 'test123'
    //         }
    //       ],
    //       query: 'test123',
    //       skipUrlSync: false,
    //       type: 'textbox'
    //     }
    //   ]
    // },
    // time: {
    //   from: '1998-01-01T00:00:00.000Z',
    //   to: '2021-12-01T23:59:59.000Z'
    // },
    // time: {
    //   from: 'now-8h',
    //   to: 'now+16h'
    // },
    // timepicker: {
    //   hidden: false,
    //   refresh_intervals: [
    //     '5s',
    //     '10s',
    //     '30s',
    //     '1m',
    //     '5m',
    //     '15m',
    //     '30m',
    //     '1h',
    //     '2h',
    //     '1d'
    //   ],
    //   time_options: ['5m', '15m', '1h', '6h', '12h', '24h', '2d', '7d', '30d']
    // },
    timezone: 'utc' // utc | browser | Europe/Andorra | ...
    // comment: ''
  }

  // }
}
