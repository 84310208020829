import { WsModflow } from '@/websocket'
import {
  SIM_MODEL,
  PointParameters,
  LineStringParameters,
  PolygonParameters
} from '@/models'
import {
  FEATURE_MULTI_LAYER
} from '@/models/utils'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { iterateFileNode2ResourceUuid } from './utils'

export const GEO_TYPE = {
  POINT: 'point',
  LINE_STRING: 'linestring',
  POLYGON: 'polygon'
}

export const MAP_LAYERTYPE_GEOTYPE = {
  circle: GEO_TYPE.POINT,
  line: GEO_TYPE.LINE_STRING,
  fill: GEO_TYPE.POLYGON
}

export const getGeojsonItem = () => ({
  geoFile: null,
  paramFile: null
})

const getClassByLayerType = layerType => {
  switch (layerType) {
    case 'circle':
      return PointParameters
    case 'line':
      return LineStringParameters
    case 'fill':
      return PolygonParameters
  }
}

const getModflowModel = (source = null) => {
  const sourceGrid = source?.MODFLOW?.Grid

  return {
    template: {
      point: new PointParameters(source?.template?.point),
      linestring: new LineStringParameters(source?.template?.linestring),
      polygon: new PolygonParameters(source?.template?.polygon)
    },
    MODFLOW: {
      Grid: {
        nx: sourceGrid?.nx ?? 10,
        ny: sourceGrid?.ny ?? 10,
        nz: sourceGrid?.nz ?? 5
      }
    }
  }
}
const getFemwaterMeshModel = (source = null) => {
  const sourceMesh = source?.FEMWATER?.Mesh

  return {
    template: {
      point: new PointParameters(source?.template?.point),
      linestring: new LineStringParameters(source?.template?.linestring),
      polygon: new PolygonParameters(source?.template?.polygon)
    },
    FEMWATER: {
      Mesh: {
        nl: sourceMesh?.nl ?? 10
      }
    }
  }
}
const getModflowParams = (type = '') => ({
  type,
  default: null,
  features: []
})

const getModflowSimData = () => ({
  image: '',
  output: {
    mapsetUuid: ''
  },
  input: {
    modelFile: '',
    geojsonList: {
      point: [],
      linestring: [],
      polygon: []
    }
  }
})

const state = () => ({
  modflowModel: getModflowModel(),
  modflowParams: getModflowParams(),
  simData: getModflowSimData()
})

const getters = {
  defaultParameters: state => state.modflowParams.default
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  initModflowParameters: ({ commit }) => {
    commit('setState', {
      modflowParams: getModflowParams()
    })
  },
  initSimData: ({ commit }) => {
    commit('setState', {
      simData: getModflowSimData()
    })
  },
  createNewParameters: ({ commit, state }, { layerNode } = {}) => {
    if (!layerNode) return

    const geojsonType = MAP_LAYERTYPE_GEOTYPE[layerNode.type]
    const ParametersClass = getClassByLayerType(layerNode.type)
    const parameters = ParametersClass && new ParametersClass()

    // 新增檔案時, features保持null, 長度等同features
    const features = layerNode.source.geoJsonData.features.map(feature => {
      if (FEATURE_MULTI_LAYER.is(feature)) {
        return Array.from({ length: FEATURE_MULTI_LAYER.getLength(feature) }, () => null)
      }

      return null
    })

    commit('setState', {
      modflowParams: {
        ...state.modflowParams,
        type: geojsonType,
        default: parameters,
        features
      }
    })
  },
  editParameters: ({ commit }, {
    layerNode, editFileNode,
    featureIndex = null,
    layerIndex = null
  } = {}) => {
    const ParametersClass = getClassByLayerType(layerNode.type)

    const defaultSource = editFileNode.fileContent.default
    const defaultParameters = ParametersClass && new ParametersClass(defaultSource)

    // 編輯feature時, 才將對應的featureParams做class
    const features = [...editFileNode.fileContent.features]
    if (featureIndex != null) {
      const hasLayer = layerIndex != null
      const parametersSource = !hasLayer
        ? features[featureIndex]
        : features[featureIndex][layerIndex]

      const parameters = new ParametersClass(parametersSource || defaultSource)

      if (!hasLayer) {
        features.splice(featureIndex, 1, parameters)
      } else {
        const layers = [...features[featureIndex]]
        layers.splice(layerIndex, 1, parameters)
        features.splice(featureIndex, 1, layers)
      }
    }

    commit('setState', {
      modflowParams: {
        ...editFileNode.fileContent,
        type: MAP_LAYERTYPE_GEOTYPE[layerNode.type],
        default: defaultParameters,
        features
      }
    })
  },
  createNewModel: ({ commit, rootState }) => {
    const { MODFLOW, FEMWATER_MESH } = SIM_MODEL

    const model = rootState.simulation.model
    let modelParams
    switch (model) {
      case MODFLOW:
        modelParams = getModflowModel()
        break
      case FEMWATER_MESH:
        modelParams = getFemwaterMeshModel()
        break
    }

    if (!modelParams) {
      return Promise.reject(i18n.t('api_errors.data_error'))
    }

    commit('setState', {
      modflowModel: modelParams
    })
  },
  editModel: ({ commit, rootState }, { editFileNode } = {}) => {
    if (!editFileNode) {
      return
    }
    const model = rootState.simulation.model
    const source = editFileNode.fileContent

    const { MODFLOW, FEMWATER_MESH } = SIM_MODEL

    let modelParams
    switch (model) {
      case MODFLOW:
        modelParams = getModflowModel(source)
        break
      case FEMWATER_MESH:
        modelParams = getFemwaterMeshModel(source)
        break
    }

    if (!modelParams) {
      return Promise.reject(i18n.t('api_errors.data_error'))
    }

    commit('setState', {
      modflowModel: modelParams
    })
  },
  applyModalParameters: ({ commit, state }, { layerNode } = {}) => {
    if (!layerNode) {
      return
    }

    const layerType = layerNode.type
    const ParametersClass = getClassByLayerType(layerType)
    const templateKey = MAP_LAYERTYPE_GEOTYPE[layerType]
    const source = state.modflowModel.template[templateKey]

    commit('setModflowParams', {
      default: new ParametersClass(source)
    })
  },
  start: ({ state, rootState, rootGetters }, { project } = {}) => {
    const wsModflow = new WsModflow()

    return new Promise((resolve, reject) => {
      wsModflow.$on(wsModflow.TASK.SUCCESS, data => {
        resolve(data)
      })

      wsModflow.$on(wsModflow.EVENT.ERROR, error => {
        reject(error)
      })

      const taskData = cloneDeep(state.simData)
      Object.keys(taskData.input.geojsonList).forEach(key => {
        if (!taskData.input.geojsonList[key].length) {
          taskData.input.geojsonList[key] = null
        }
      })

      let image
      switch (rootState.simulation.model) {
        case SIM_MODEL.MODFLOW:
          image = taskData.image
          break
        case SIM_MODEL.FEMWATER_MESH:
          [image] = rootGetters['simulation/model']?.images?.slice?.(-1)
          image = image?.imageName
          break
      }
      delete taskData.image

      iterateFileNode2ResourceUuid(taskData)

      wsModflow.start({
        image,
        projectUuid: project.uuid,
        taskData
      })
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setModflowParams: (state, payload = {}) => {
    Object.assign(state.modflowParams, payload)
  },
  setSimData: (state, payload = {}) => {
    Object.assign(state.simData, payload)
  }
}

export const modflow = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
