import Axios from 'axios'

import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.s3.maplayer.data

const CancelToken = Axios.CancelToken
let cancelUpload, cancelDownload

export const apiS3MaplayerData = {
  get(params, responseType = 'json', onDownloadProgress) {
    // params = {
    //   project_uuid: String,
    //   resource_uuid: String
    // }

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params,
      responseType // 從s3 stream過來的binary data
    }

    if (typeof onDownloadProgress === 'function') {
      Object.assign(config, {
        onDownloadProgress,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelDownload = c
        })
      })
    }

    return http.get(url, config)
  },
  put(formData, onUploadProgress) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'put')

    const file = formData.get('maplayer')

    const config = {
      withCredentials,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data'
      },
      onUploadProgress,
      cancelToken: new CancelToken(c => {
        // An executor function receives a cancel function as a parameter
        file.cancelUpload = c
      })
    }

    if (typeof onUploadProgress !== 'function') {
      delete config.onUploadProgress
    }

    return http.put(url, formData, config)
  },
  cancelUpload() {
    cancelUpload('Upload canceled.')
  },
  cancelDownload() {
    cancelDownload('Upload canceled.')
  }
}
