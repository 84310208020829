import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.s3.bucket

export const apiS3Bucket = {
  get(params) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params
    }

    return http.get(url, config).then(res => res?.data?.bucket)
  }
}
