import Vue from 'vue'
import camelize from 'camelize'
import settings from '@/assets/js/settings.js'
import EventWebSocket from '@/websocket/EventWebSocket'

const wsBaseUrl = settings.WS_ENDPOINT
const url = `${wsBaseUrl}/ws/postprocessing/save`

const EVENT_MAP = {
  OPEN: 'open',
  CHECK_PERMISSION: 'check_permission',
  PERMISSION_CHECKED: 'permission_checked',
  START: 'start_job',
  STARTED: 'job_started',
  CHECK_STATUS: 'check_status',
  STATUS_CHECKED: 'status_checked',
  END: 'end_job',
  ENDED: 'job_ended',
  ERROR: 'error'
}

const TASK_STATUS_MAP = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

const data = () => ({
  ws: null,
  data: null, // {
  //   project_uuid: String,
  //   parent_resource_uuid: String, // mapset resource uuid
  //   vtk_resource_uuid: String,
  //   glb_resource_uuid: String
  // }
  taskUuid: null,
  EVENT: EVENT_MAP,
  TASK: TASK_STATUS_MAP,
  delayCheck: 500, // ms,
  errorMessage: '',
  permissions: {
    glb: false
  }
})

const computed = {
  isValidPermission() {
    return Object.values(this.permissions).every(Boolean)
  },
  vtkResourceUuid() {
    return this.data?.vtk_mesh_pairs?.[0].vtk_resource_uuid
  }
}

const beforeDestroy = function() {
  if (this.ws?.readyState === 1) {
    this.close()
  }
}

const methods = {
  send(eventName, data) {
    if (this.ws?.readyState !== EventWebSocket.OPEN) {
      return
    }

    this.ws.send(eventName, data)
  },
  start(data) {
    this.data = data
    this.connect()
  },
  connect() {
    this.ws = new EventWebSocket(url)

    this.ws.addEventListener(this.EVENT.OPEN, this.onOpen)

    this.ws.addEventListener('message', e => {
      const versionUuid = e?.data?.version_uuid
      if (versionUuid) {
        this.data.version_uuid = versionUuid
      }
    })

    this.ws.addEventListener(this.EVENT.PERMISSION_CHECKED, this.onPermissionChecked)
    // Trigger check task after job started
    this.ws.addEventListener(this.EVENT.STARTED, this.onStarted)
    // Task status handler
    this.ws.addEventListener(this.EVENT.STATUS_CHECKED, this.onStatusChecked)
    // Job ended handler
    this.ws.addEventListener(this.EVENT.ENDED, this.onEnded)

    this.ws.addEventListener(this.EVENT.ERROR, this.onError)
    this.ws.onclose = this.onClose
  },
  sendCheckPermission(permissionName, resourceUuid) {
    const data = {
      permission_name: permissionName,
      resource_uuid: resourceUuid,
      project_uuid: this.data.project_uuid
      // parent_resource_uuid: this.data.parent_resource_uuid
      // version_uuid: this.data.version_uuid
    }

    if (this.data.version_uuid) {
      data.version_uuid = this.data.version_uuid
    }

    this.send(this.EVENT.CHECK_PERMISSION, data)
  },
  sendStartJob() {
    this.send(this.EVENT.START, {
      version_uuid: this.data.version_uuid,
      glb_resource_uuid: this.data.glb_resource_uuid,
      plot_settings: this.data.plot_settings,
      bounding_box_vtk_resource_uuid: this.data.bounding_box_vtk_resource_uuid || null
    })
  },
  sendCheckStatus() {
    this.send(this.EVENT.CHECK_STATUS, {
      task_uuid: this.taskUuid,
      version_uuid: this.data.version_uuid
    })
  },
  sendEndJob() {
    this.send(this.EVENT.END, this.data)
  },
  onOpen(e) {
    if (!this.data) {
      return
    }

    if (this.taskUuid) {
      this.sendCheckStatus()

      return
    }

    // create a job task
    // this.sendStartJob()

    this.sendCheckPermission('glb', this.data.glb_resource_uuid)
    // this.sendCheckPermission('glb', this.data.glb_resource_uuid)
  },
  onPermissionChecked(e) {
    const permissionName = e.data.permission_name

    // resource permission checked
    if (
      permissionName &&
      Object.prototype.hasOwnProperty.call(this.permissions, permissionName)
    ) {
      this.permissions[permissionName] = true
    }

    if (!this.isValidPermission) {
      this.sendCheckPermission('glb', this.data.glb_resource_uuid)

      return
    }

    // 所有permission都pass就start job
    this.sendStartJob()
  },
  onStarted(e) {
    this.$emit(this.EVENT.STARTED, camelize(e.data))

    this.taskUuid = e.data.task_uuid

    // Trigger check process
    this.sendCheckStatus()
  },
  onStatusChecked(e) {
    this.$emit(this.EVENT.STATUS_CHECKED, camelize(e.data))

    const status = e.data.status

    // Success case
    if (status === this.TASK.SUCCESS) {
      this.$emit(this.TASK.SUCCESS, camelize(e.data))

      setTimeout(() => {
        this.sendEndJob()
      }, 200)
      return
    }

    // Failure case
    if (status === this.TASK.FAILURE) {
      this.onError(new Error('發生未知的錯誤'))
      return
    }

    // Pedding case
    setTimeout(() => {
      this.sendCheckStatus()
    }, this.delayCheck)
  },
  onEnded(e) {
    this.$emit(this.EVENT.ENDED, camelize(e.data))

    this.close()

    this.$destroy()
  },
  onError(e) {
    const error = e instanceof Error ? e : new Error('Websocket連線發生錯誤')
    this.$emit(this.EVENT.ERROR, error)

    this.$destroy()

    // this.close()
  },
  onClose(e) {
    if (e.code !== 1000 && e.code) {
      console.debug(e)
      this.$emit(this.EVENT.ERROR, new Error(`連線發生錯誤，錯誤代碼:${e.code}`))
    }
  },
  close() {
    this.ws.close()
  }
}

export class WsSave extends Vue {
  constructor() {
    super({
      data,
      computed,
      beforeDestroy,
      methods
    })
  }
}
