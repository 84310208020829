import dot from 'dot-object'
// import { base64StringToArrowTable, arrowTableToDataFrame } from '@grafana/data'

dot.keepArray = true

export const formatSnapshotData = (queryResults, fieldConfig) => {
  // queryResults 是每個Target進行query後得到data的結果
  // 每個target query會得到n個Fields, dataframe的fileds僅代表Time和某Field
  // 最後攤平所有target query得到的Fields成一個Array Object就是snapshotData
  if (!Array.isArray(queryResults)) return []

  return queryResults.flatMap(([refId, qResult]) => {
    return qResult.frames
      // .map(dataframeB64String =>
      //   arrowTableToDataFrame(base64StringToArrowTable(dataframeB64String))
      // )
      .flatMap(frame => {
        // 自己把overrides取代defaults的設定
        const override = fieldConfig.overrides.find(o => o.matcher.options === refId)

        const config = override
          ? dot.object({
            ...dot.dot(fieldConfig.defaults),
            ...dot.dot(Object.fromEntries(override.properties.map(p => [p.id, p.value])))
          })
          : fieldConfig.defaults

        return {
          refId: refId,
          fields: frame.schema.fields.map((field, iField) => {
            return {
              ...field,
              config,
              // values: Object.values(field.values.toArray())
              values: frame.data.values[iField]
            }
          }),
          meta: frame.schema.meta,
          name: frame.schema.name
        }
      })
  })
}
