import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.commonauth.users

export const apiCommonauthUsers = {
  get() {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials
    }

    return http.get(url, config)
  },
  post(data) {
    // check user exists
    // data = {
    //   username: 'minyitsai',
    //   query: 'fuzzy' | 'exact'
    // }

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  },
  head(code) {
    const url = `${apiConfig.url}/${code}`

    const withCredentials = checkTokenRequired(apiConfig, 'head')

    const config = {
      withCredentials
    }

    return http.head(url, config)
  },
  delete(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials,
      data
    }

    return http.delete(url, config)
  }
}
