;
export const isNumber = num => typeof num === 'number' && !Number.isNaN(num)

export const setProperties = Object.assign

export * from './file'
export * from './layer'
export * from './source'
export * from './metadata'
export * from './settings'
