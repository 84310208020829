import Axios from 'axios'

import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.s3.stagingfile.data

const CancelToken = Axios.CancelToken
let cancelDownload

export const apiS3StagingfileData = {
  get(params, responseType = 'blob', onDownloadProgress) {
    // params = {
    //   project_uuid: String,
    //   resource_uuid: String
    // }

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params,
      responseType // 從s3 stream過來的binary data
    }

    if (typeof onDownloadProgress === 'function') {
      Object.assign(config, {
        onDownloadProgress,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelDownload = c
        })
      })
    }

    return http.get(url, config)
  },

  cancelDownload() {
    cancelDownload('Upload canceled.')
  }
}
