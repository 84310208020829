;
export const FILE_TYPE = {
  FOLDER: 'folder',
  MAPSET: 'mapset',
  FILE: 'file'
}

const getTypeByParentDefault = {
  get: function(target, name) {
    return Object.hasOwnProperty.call(target, name) ? target[name] : FILE_TYPE.FOLDER
  }
}
const getTypeByParentDefine = {
  [FILE_TYPE.FOLDER]: FILE_TYPE.MAPSET,
  [FILE_TYPE.MAPSET]: FILE_TYPE.FILE
}
export const getTypeByParent = new Proxy(getTypeByParentDefine, getTypeByParentDefault)
