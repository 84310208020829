import camelize from 'camelize'

import { form } from './project-form'

import {
  apiProjectProjects,
  apiProjectResourcePermissions,
  apiInfluxBuckets
} from '@/api'

const formatProjects = baseProjects => {
  const { owner, member } = baseProjects
  const ownerProjects = (Array.isArray(owner) && owner) || []
  const memberProjects = (Array.isArray(member) && member) || []
  const projects = [
    ...ownerProjects.map(p => ({
      ...p,
      permission: 'owner',
      isOwner: true
    })),
    ...memberProjects.map(p => ({
      ...p,
      permission: 'member',
      isOwner: false
    }))
  ]

  return projects.map(project => {
    const resources =
      (Array.isArray(project.resources) && project.resources) || []

    const S3_BUCKET = resources.find(
      resource => resource.resourceType === 'S3_BASE'
    )
    const GRAFANA_ROOT = resources.find(
      resource => resource.resourceType === 'GRAFANA_ROOT'
    )
    const INFLUX_BUCKET = resources.find(
      resource => resource.resourceType === 'INFLUX_BUCKET'
    )

    project.s3BucketId = S3_BUCKET?.uuid
    project.grafanaId = GRAFANA_ROOT?.uuid
    project.influxBucketId = INFLUX_BUCKET?.uuid

    return {
      id: project.uuid,
      name: project.projectName,
      ...project
    }
  })
}

const state = () => ({
  projects: [],
  isLoadingProjects: false
})

const getters = {
  ownerProjects: state => state.projects.filter(p => p.permission === 'owner'),
  memberProjects: state =>
    state.projects.filter(p => p.permission === 'member'),
  getProjectById: state => projectId => {
    if (!projectId) return

    return state.projects.find(project => project.id === projectId)
  },
  getProjectByRoute: (_, getters) => route => {
    const projectId = route.params.projectId
    return getters.getProjectById(projectId)
  },
  getRoleByResourceId: _ => (project, resourceId) => {
    // permission = {
    //   resource: {
    //     resource_type: String, // GRAFANA_ROOT | GRAFANA_DASHBOARD | ...
    //     uuid: String
    //   },
    //   role: {
    //     role_name: String,
    //     weight: Number,
    //     read: Boolean,
    //     create: Boolean,
    //     update: Boolean,
    //     delete: Boolean
    //   }
    // }
    if (!Array.isArray(project?.permissions) || !resourceId) return

    return project.permissions
      .filter(
        permission =>
          permission?.resource?.uuid === resourceId &&
          typeof permission.role.weight === 'number'
      )
      .sort((a, b) => a.role.weight - b.role.weight)
      .pop()?.role
  },
  getResourceByResourceId: _ => (project, resourceId) => {
    // resource = {
    //   resource_type: String, // GRAFANA_ROOT | GRAFANA_DASHBOARD | ...
    //   uuid: String
    // }

    if (!Array.isArray(project?.resources)) return

    return project.resources
      .find(resource => resource.uuid === resourceId)
  }
}

const actions = {
  init: ({ commit, dispatch }) => {
    dispatch('form/init')
    commit('setState', state())
  },
  fetchProjects: ({ commit }) => {
    commit('setState', {
      isLoadingProjects: true
    })
    return apiProjectProjects.get()
      .then(res => {
        const projects = formatProjects(camelize(res?.data?.data))
        commit('setState', {
          projects
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingProjects: false
        })
      })
  },
  fetchProject: ({ commit, dispatch, state }, { projectId } = {}) => {
    if (!projectId) return

    return Promise.all([
      apiProjectProjects.get(projectId),
      dispatch('fetchPermissions', { projectId })
    ]).then(async results => {
      const [baseProject] = formatProjects(camelize(results[0]?.data?.data))

      if (!baseProject) return

      const permissions = results[1]
      const project = {
        ...baseProject,
        permissions: (Array.isArray(permissions) && permissions) || []
      }

      if (project.influxBucketId) {
        const INFLUX_BUCKET = await apiInfluxBuckets
          .get(project.influxBucketId, (!project.isOwner && projectId) || null)
          .then(res => camelize(res?.data?.data))
          .catch(() => {})

        project.influxBucketName = INFLUX_BUCKET?.bucketName
      }

      const oriProject = state.projects.find(p => p.uuid === project.uuid)

      if (oriProject) {
        commit('setState', {
          projects: state.projects.map(p => (p === oriProject ? project : p))
        })
      } else {
        const projects = [project, ...state.projects].sort((a, b) =>
          a.modifyDate.localeCompare(b.modifyDate)
        )

        commit('setState', {
          projects
        })
      }
    })
  },
  fetchPermissions: ({ state, commit }, { projectId } = {}) => {
    if (!projectId) return []

    return apiProjectResourcePermissions.get(projectId).then(res => {
      const permissions = camelize(res?.data?.data)

      const project = state.projects.find(project => project.id === projectId)

      if (!project) return permissions

      commit('setProject', {
        project,
        permissions
      })

      return permissions
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setProject: (_, { project, ...payload }) => {
    Object.assign(project, payload)
  }
}

export const projects = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    form
  }
}
