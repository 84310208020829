import { WsMassbalance, WsMassbalanceSlice } from '@/websocket'
import { iterateFileNode2ResourceUuid } from './utils'
import { cloneDeep } from 'lodash'

import { StagingFile } from '@/models'

import i18n from '@/i18n'

const getMassbalanceSimData = () => ({
  output: {
    mapsetUuid: ''
  },
  input: {
    geojsonList: {
      well: '',
      area: ''
    }
  },
  massbalance: {
    layer: null,
    grid: {
      nx: 51,
      ny: 51
    }
  }
})

const state = () => ({
  simData: getMassbalanceSimData()
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  initSimData: ({ commit }) => {
    commit('setState', {
      simData: getMassbalanceSimData()
    })
  },
  start: ({ state, dispatch, rootGetters }, { project } = {}) => {
    const wsMassbalance = new WsMassbalance()

    return new Promise((resolve, reject) => {
      const { TASK, EVENT } = wsMassbalance
      wsMassbalance.$on(TASK.SUCCESS, data => {
        const { vtkResourceUuid, months, rangeMin, rangeMax } = data.output

        // resolve(data)

        if (!vtkResourceUuid) {
          resolve(data)
          return
        }

        resolve(dispatch('files/fetchFileSubTree', {
          project,
          fileUuid: vtkResourceUuid
        }, { root: true })
          .then(vtkFileNode => {
            if (!vtkFileNode) {
              return
            }
            return dispatch('files/createMassbalanceGlbFiles', {
              project,
              vtkFileNode,
              months,
              rangeMin,
              rangeMax
            }, { root: true })
          })
        )
      })

      wsMassbalance.$on(EVENT.ERROR, error => {
        reject(error)
      })

      const image = rootGetters['simulation/model']?.images?.find?.(image => image.tag === 'massbalance01')

      const taskData = cloneDeep(state.simData)

      iterateFileNode2ResourceUuid(taskData)

      wsMassbalance.start({
        image: image?.imageName,
        task_data: taskData,
        project_uuid: project.uuid
      })
    })
  },
  startSlice: async ({ rootGetters, dispatch }, { project, layerNode } = {}) => {
    const wsMassbalanceSlice = new WsMassbalanceSlice()

    layerNode.setProperties({
      wsMassbalanceSlice
    })

    // if (!layerNode.mbSliceStagingFile) {
    //   const mbSliceStagingFile = await dispatch('postprocess/createStagingFile', {
    //     project,
    //     glbLayerNode: layerNode
    //   }, { root: true })
    //     .catch(error => {
    //       return error
    //     })

    //   if (mbSliceStagingFile instanceof Error) {
    //     return Promise.reject(mbSliceStagingFile)
    //   }

    //   layerNode.setProperties({
    //     mbSliceStagingFile
    //   })
    // }
    return new Promise((resolve, reject) => {
      const { TASK, EVENT } = wsMassbalanceSlice
      wsMassbalanceSlice.$on(TASK.SUCCESS, data => {
        const stagingFileResourceUuid = data?.output?.stagingFileResourceUuid

        if (!stagingFileResourceUuid) {
          wsMassbalanceSlice.onError(new Error(i18n.t('api_errors.data_error')))

          return
        }

        dispatch('postprocess/fetchStagingFileContent', {
          project,
          stagingFileUuid: stagingFileResourceUuid,
          responseType: 'json'
        }, { root: true })
          .then(res => {
            const fileContent = res.data
            const stagingFile = new StagingFile(
              stagingFileResourceUuid,
              {
                name: layerNode.name,
                fileContent,
                settings: cloneDeep(layerNode.settings)
              }
            )
            layerNode.setProperties({
              mbSliceStagingFile: stagingFile
            })
            resolve(fileContent)
          })
          .catch(reject)
          .finally(() => {
            wsMassbalanceSlice.sendEndJob()
          })
      })

      wsMassbalanceSlice.$on(EVENT.ERROR, error => {
        reject(error)
      })

      const image = rootGetters['simulation/model']?.images?.find?.(image => image.tag === 'massbalance02')

      const taskData = {
        // output: {
        //   staging_file_resource_uuid: layerNode.mbSliceStagingFile
        // },
        input: {
          line_geojson: layerNode.massbalanceStringline,
          dat_maplayer_resource_uuid: layerNode.settings?.massbalance?.datMaplayerResourceUuid
        }
      }

      wsMassbalanceSlice.start({
        image: image?.imageName,
        task_data: taskData,
        project_uuid: project?.uuid
      })
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const massbalance = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
