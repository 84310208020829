export const MAP_PAINT_TYPE_DRAW_TYPE = {
  circle: 'point',
  line: 'lineString',
  fill: 'polygon'
}

const state = () => ({
  drawer: null,
  currentDrawType: null,
  editLayerNode: null,
  hasUpdate: false,
  isDrawing: false
})

const getters = {
  isEdit: state => !!state.editLayerNode
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  }
}

const mutations = {
  setState: (state, payload) => {
    Object.assign(state, payload)
  }
}

export const drawer = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
