import { apiInfluxMeasurements } from '@/api'

const state = () => ({
  formData: {
    name: ''
  }
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },

  setFormData: ({ commit }, payload) => {
    commit('setFormData', payload)
  },

  submit: ({ state, rootState }) => {
    const formData = state.formData

    return apiInfluxMeasurements.post({
      measurement_name: formData.name,
      parent_resource_uuid: rootState.influxBucketId
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setFormData: (state, payload) => {
    Object.assign(state.formData, payload)
  }
}

export const form = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
