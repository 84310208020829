// Property = {
//   value: String,
//   valueType: String,
//   unit: String, // optional
//   apply: Boolean, // optional
//   target: String, // optional, 提供SameAs, EqualAs功能
//   targetOptions: Array<Object::<v-select items>>, // optional, 提供Select指定target
//   render: Render,
//   [<property_key>]: Property,
//   ...
// }

// Render = {
//   layout: 'children', |  'additions', // optional
//   tag: 'fieldset', // optional
//   legend: Property, // optional, use with tag is fieldset
//   class: Object::<v-bind:class>,
//   disabled: Boolean, // optional
//   show: Boolean, // option, false時隱藏Tab, 只提供info有component的Tab使用
//   showTabHeader: Boolean, // option, false時隱藏TabHeader, 只提供info有component的Tab使用
//   info: {
//     text: String, // optional
//     buttonText: String // optional
//     component: String // component name, NOTE: render成v-tab-item一定要指定
//   }
// }
import { Parameters } from './parameters'
// polygon properties constructor
function PolygonParameters(source) {
  this.Physical = {
    render: {
      info: {
        text: 'Physical Properties',
        component: 'Physical'
      }
    },
    Conductivity: {
      value: '10.0',
      valueType: 'string',
      unit: 'm/day',
      render: {
        info: {
          text: 'Conductivity'
        }
      },
      Random: {
        apply: false,
        value: '',
        valueType: 'string',
        render: {
          layout: 'children',
          disabled: true,
          info: {
            buttonText: 'Random...'
          }
        }
      }
    },
    KxKy: {
      value: '1.0',
      valueType: 'string',
      render: {
        info: {
          text: 'Kx/Ky'
        }
      }
    },
    KxKz: {
      value: '10.0',
      valueType: 'string',
      render: {
        info: {
          text: 'Kx/Ky'
        }
      }
    },
    OrientationOfAnisFInXY: {
      value: '0.0',
      valueType: 'string',
      unit: 'degree',
      render: {
        info: {
          text: 'Orientation of AnisF in XY'
        }
      }
    },
    OrientationOfAnisFInXZ: {
      value: '0.0',
      valueType: 'string',
      unit: 'degree',
      render: {
        info: {
          text: 'Orientation of AnisF in XZ'
        }
      }
    },
    RetardationFactor: {
      value: '0.0',
      valueType: 'string',
      render: {
        info: {
          text: 'Retardation Factor'
        }
      }
    },
    PartitioningKd: {
      value: '0.0',
      valueType: 'string',
      unit: 'm^3/g',
      render: {
        info: {
          text: 'Partitioning - Kd'
        }
      },
      Random: {
        apply: false,
        render: {
          layout: 'children',
          disabled: true,
          info: {
            buttonText: 'Random...'
          }
        }
      }
    },
    FirstOrderDecay: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'First Order Decay'
            }
          }
        }
      },
      DecayCoeff: {
        value: '0.0',
        valueType: 'string',
        render: {
          layout: 'children',
          info: {
            text: 'Decay Coeff.'
          }
        }
      },
      HalfLife: {
        value: '0.0',
        valueType: 'string',
        render: {
          layout: 'children',
          info: {
            text: 'Half Life'
          }
        }
      }
    },
    SoilParticleDensity: {
      value: '2650.0',
      valueType: 'string',
      unit: 'g/m^3',
      render: {
        info: {
          text: 'Soil Particle Density'
        }
      }
    },
    SpecificYield: {
      value: '0.0',
      valueType: 'string',
      unit: 'm^3/g',
      render: {
        info: {
          text: 'Specific Yield'
        }
      }
    },
    SpecificStorage: {
      value: '0.0',
      valueType: 'string',
      unit: '1/m',
      render: {
        info: {
          text: 'Specific Storage'
        }
      }
    },
    EffectivePorosity: {
      value: '0.3',
      valueType: 'string',
      render: {
        info: {
          text: 'Effective Porosity'
        }
      },
      Random: {
        apply: false,
        render: {
          layout: 'children',
          disabled: true,
          info: {
            buttonText: 'Random...'
          }
        }
      }
    },
    MolecularDiffusion: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Molecular Diffusion'
            }
          }
        }
      },
      Dxx: {
        value: '0.0',
        valueType: 'string',
        unit: 'm^2/day',
        render: {
          layout: 'children',
          info: {
            text: 'D*xx'
          }
        }
      },
      Dyy: {
        value: '0.0',
        valueType: 'string',
        unit: 'm^2/day',
        render: {
          layout: 'children',
          info: {
            text: 'D*yy'
          }
        }
      },
      Dzz: {
        value: '0.0',
        valueType: 'string',
        unit: 'm^2/day',
        render: {
          layout: 'children',
          info: {
            text: 'D*zz'
          }
        }
      },
      OrientXY: {
        value: '0.0',
        valueType: 'string',
        unit: 'degree',
        render: {
          layout: 'children',
          info: {
            text: 'Orient_XY'
          }
        }
      },
      OrientXZ: {
        value: '0.0',
        valueType: 'string',
        unit: 'degree',
        render: {
          layout: 'children',
          info: {
            text: 'Orient_XZ'
          }
        }
      }
    },
    LocalDispersion: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Local Dispersion'
            }
          }
        }
      },
      Long: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Long.'
          }
        }
      },
      Trans: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Trans.'
          }
        }
      },
      Vert: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Vert.'
          }
        }
      }
    },
    Macrodispersion: {
      apply: false,
      render: {
        disabled: true,
        info: {
          buttonText: 'Macrodispersion...'
        }
      }
    }
  }

  this.SourcesAndSinks = {
    render: {
      info: {
        text: 'Sources and Sinks',
        component: 'SourcesAndSinks'
      }
    },
    Rech: {
      value: '0.0',
      valueType: 'string',
      unit: 'm/day',
      render: {
        info: {
          text: 'Rech'
        }
      },
      Transient: {
        apply: false,
        value: [],
        valueType: 'array',
        render: {
          layout: 'additions',
          disabled: true,
          info: {
            buttonText: 'Transient'
          }
        }
      },
      Random: {
        apply: false,
        render: {
          layout: 'children',
          disabled: true,
          info: {
            buttonText: 'Random...'
          }
        }
      }
    },
    RchC: {
      value: '1.0',
      valueType: 'string',
      unit: 'ppm',
      render: {
        info: {
          text: 'RchC'
        }
      },
      Transient: {
        apply: false,
        value: [],
        valueType: 'array',
        render: {
          layout: 'additions',
          disabled: true,
          info: {
            buttonText: 'Transient'
          }
        }
      }
    },
    SourceConcentration: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Source Concentration'
            }
          }
        }
      },
      case: 'Instantaneous',
      cases: {
        Instantaneous: {
          value: '0.0',
          valueType: 'string',
          unit: 'ppm',
          render: {
            info: {
              text: 'Instantaneous'
            }
          }
        },
        Continuous: {
          value: '0.0',
          valueType: 'string',
          unit: 'ppm',
          render: {
            info: {
              text: 'Continuous'
            }
          },
          Transient: {
            apply: false,
            value: [],
            valueType: 'array',
            render: {
              layout: 'additions',
              disabled: true,
              info: {
                buttonText: 'Trans...'
              }
            }
          }
        },
        OtherComtaminantSoil: {
          render: {
            disabled: true,
            info: {
              buttonText: 'Other Comtaminant Soil...'
            }
          }
        }
      }
    },
    River: {
      apply: false,
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'River'
            }
          }
        }
      },
      RivStage: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Riv Stage'
          }
        },
        Transient: {
          apply: false,
          value: [],
          valueType: 'array',
          render: {
            layout: 'additions',
            disabled: true,
            info: {
              buttonText: 'Transient'
            }
          }
        }
      },
      RivC: {
        value: '0.0',
        valueType: 'string',
        unit: 'ppm',
        render: {
          layout: 'children',
          info: {
            text: 'Riv C'
          }
        },
        Transient: {
          apply: false,
          value: [],
          valueType: 'array',
          render: {
            layout: 'additions',
            disabled: true,
            info: {
              buttonText: 'Transient'
            }
          }
        }
      },
      RivCond: {
        value: '1000.0',
        valueType: 'string',
        unit: 'm^2/day',
        render: {
          layout: 'children',
          info: {
            text: 'Riv Cond'
          }
        }
      },
      RiverBottom: {
        render: {
          layout: 'children',
          tag: 'fieldset',
          legend: {
            render: {
              info: {
                text: 'River Bottom'
              }
            }
          }
        },
        case: 'Constant',
        cases: {
          Constant: {
            value: '-30.0',
            valueType: 'string',
            unit: 'm',
            render: {
              info: {
                text: 'Constant'
              }
            }
          },
          SameAs: {
            target: 'TopElevation',
            render: {
              info: {
                text: 'Same as Top Elevation'
              }
            }
          }
        }
      },
      Leakance: {
        render: {
          layout: 'children',
          tag: 'fieldset',
          legend: {
            render: {
              info: {
                text: 'Leakance'
              }
            }
          }
        },
        case: 'Constant',
        cases: {
          Constant: {
            value: '-30.0',
            valueType: 'string',
            unit: '1/day',
            render: {
              info: {
                text: 'Constant'
              }
            }
          },
          SedimentProperties: {
            render: {
              disabled: true,
              info: {
                buttonText: 'Sediment Properties...'
              }
            }
          }
        }
      }
    },
    Drain: {
      apply: false,
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Drain'
            }
          }
        }
      },
      Leakance: {
        value: '1.0',
        valueType: 'string',
        unit: '1/day',
        render: {
          layout: 'children',
          info: {
            text: 'Leakance'
          }
        }
      },
      DrnCond: {
        value: '100.0',
        valueType: 'string',
        unit: 'm^2/day',
        render: {
          layout: 'children',
          info: {
            text: 'Drn Cond'
          }
        }
      },
      DrainEley: {
        render: {
          layout: 'children',
          tag: 'fieldset',
          legend: {
            render: {
              info: {
                text: 'Drain Eley'
              }
            }
          }
        },
        case: 'Elevation',
        cases: {
          Elevation: {
            value: '0.0',
            valueType: 'string',
            unit: 'm',
            render: {
              info: {
                text: 'Elevation'
              }
            }
          },
          SameAs: {
            target: 'TopElevation',
            render: {
              info: {
                text: 'Same as Top Elevation'
              }
            }
          }
        }
      }
    },
    PrescribedHead: {
      apply: false,
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Prescribed Head'
            }
          }
        }
      },
      case: 'Constant',
      cases: {
        Constant: {
          value: '0.0',
          valueType: 'string',
          unit: 'm',
          render: {
            info: {
              text: 'Constant'
            }
          },
          Transient: {
            apply: false,
            render: {
              layout: 'additions',
              disabled: true,
              class: {
                'ml-5': true
              },
              info: {
                buttonText: 'Transient'
              }
            }
          }
        },
        SameAs: {
          target: 'TopElevation',
          render: {
            info: {
              text: 'Same as Top Elevation'
            }
          }
        }
      }
    },
    GeneralHeadDependentLeakage: {
      apply: false,
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'General Head Dependent Leakage'
            }
          }
        }
      },
      HDEPConc: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'HDEP Conc'
          }
        }
      },
      Leakance: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Leakance'
          }
        }
      },
      SourceHead: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Source Head'
          }
        }
      }
    },
    Evapotranspiration: {
      apply: false,
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Evapotranspiration'
            }
          }
        }
      },
      MaxET: {
        value: '0.0',
        valueType: 'string',
        unit: 'm/day',
        render: {
          layout: 'children',
          info: {
            text: 'Max ET'
          }
        }
      },
      Depth1: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Depth 1'
          }
        }
      },
      Depth2: {
        value: '0.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Depth 2'
          }
        }
      }
    }
  }

  this.AquiferElevationsCalibrationData = {
    render: {
      info: {
        text: 'Aquifer Elevations / Calibration Data',
        component: 'AquiferElevationsCalibrationData'
      }
    },
    AquiferElevation: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Aquifer Elevation'
            }
          }
        }
      },
      TopElevation: {
        render: {
          layout: 'children',
          tag: 'fieldset',
          legend: {
            render: {
              info: {
                text: 'Top Elevation'
              }
            }
          }
        },
        case: 'Constant',
        cases: {
          Constant: {
            value: '10.0',
            valueType: 'string',
            unit: 'm',
            render: {
              info: {
                text: 'Constant'
              }
            }
          },
          Random: {
            disabled: true,
            render: {
              info: {
                buttonText: 'Random...'
              }
            }
          },
          SameAsStartingHead: {
            target: 'StartingHead',
            render: {
              info: {
                text: 'Same as Starting Head'
              }
            }
          }
        }
      },
      BottomElevation: {
        value: '-50.0',
        valueType: 'string',
        unit: 'm',
        render: {
          layout: 'children',
          info: {
            text: 'Bottom Elevation'
          }
        },
        Random: {
          apply: false,
          render: {
            layout: 'children',
            disabled: true,
            info: {
              buttonText: 'Random...'
            }
          }
        }
      }
    },
    StartingHead: {
      value: '10.0',
      valueType: 'string',
      unit: 'm',
      render: {
        info: {
          text: 'Starting Head'
        }
      }
    }
  }

  Parameters.call(this, source)
}

PolygonParameters.prototype = Object.create(Parameters.prototype)

PolygonParameters.prototype.constructor = PolygonParameters

export { PolygonParameters }
