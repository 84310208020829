import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.project.resources
const url = apiConfig.url
export const apiProjectResources = {
  get() {
    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const apiUrl = url

    const config = {
      withCredentials
    }

    return http.get(apiUrl, config)
  }
}
