import ReconnectingWebSocket from 'reconnecting-websocket'

// ref: https://github.com/pladaria/reconnecting-websocket#readme

const deaultOptions = {
  // startClosed: true
  maxRetries: 0
}

export default class EventWebSocket extends ReconnectingWebSocket {
  eventListeners={}
  token=localStorage.getItem('loginToken')

  constructor(urlProvider, protocols = [], options) {
    super(urlProvider, protocols, {
      ...deaultOptions,
      ...options
    })

    super.addEventListener('message', e => {
      const data = JSON.parse(e.data)
      if (data?.message === 'Permission Error') {
        this.dispatchEvent('error', new Error('權限錯誤'))
        return
      }

      this.dispatchEvent('message', {
        wsEvent: e,
        ...data
      })

      // dispatch eventName when recive message
      this.dispatchEvent(data.event, {
        wsEvent: e,
        ...data
      })
    })

    super.addEventListener('open', e => {
      this.dispatchEvent('open', e)
    })
    super.addEventListener('error', error => {
      console.log('error and try to reconnect')
      this.dispatchEvent('error', error)
    })
    super.addEventListener('close', () => {
      this.dispatchEvent('close', null)
    })
  }

  addEventListener(eventName, listener) {
    this.eventListeners[eventName] = this.eventListeners[eventName] || []
    this.eventListeners[eventName].push(listener)
    return this// chainable
  }

  removeEventListener(eventName, listener) {
    const listeners = this.eventListeners[eventName]

    if (!Array.isArray(listeners)) {
      return
    }

    this.eventListeners[eventName] = listeners.filter(l => l !== listener)
  }

  send(eventName, data) {
    const payload = JSON.stringify({
      event: eventName,
      data: {
        token: this.token,
        ...data
      }
    })
    super.send(payload) // <= send JSON data to socket server
    return this
  }

  async dispatchEvent(eventName, message) {
    const chain = this.eventListeners[eventName]
    if (!Array.isArray(chain) || !chain.length) {
      // no eventListeners for this event
      return
    }

    // 序列化event handler
    for (const callback of chain) {
      await callback(message)
    }
  }
}
