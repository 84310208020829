export const createTag = condition => {
  const tag = {
    condition,
    key: '',
    operator: '==',
    value: '',
    isGeojsonVariable: false
  }

  if (!condition) {
    delete tag.condition
  }

  return tag
}
export const tagOperatorOptions = [
  {
    icon: 'mdi-equal',
    value: '=='
  },
  {
    icon: 'mdi-not-equal-variant',
    value: '!='
  }
]

export const formatTagTemplate = tag => {
  if (!tag.key) return ''

  return `\${${tag.key}}`
}
