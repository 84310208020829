export const definePropertyGetSet = (obj, root) => {
  // 攤平render內容到Property上
  defineRenders(obj)
  if (typeof obj.cases === 'object') {
    defineRenders(obj.cases)
  }

  // 根據prop.layout找出children和additions並設定他
  // console.log(obj)

  defineChildrenAdditions(obj)

  // 遍歷整個obj找出有apply的fieldset, 將其legend定義apply
  if (
    obj?.tag === 'fieldset' &&
    typeof obj.apply === 'boolean' &&
    typeof obj?.legend === 'object'
  ) {
    defineLegendApply(obj)
  }

  // root一開始為空, 第一個遞迴才把rootObj帶進去
  defineRootDisabled(obj, root)

  // 遞迴obj底下所有prop
  if (typeof obj.children === 'object') {
    for (const prop in obj.children) {
      definePropertyGetSet(obj.children[prop], obj)
    }
  }
  if (typeof obj.cases === 'object') {
    for (const prop in obj.cases) {
      definePropertyGetSet(obj.cases[prop], obj)
    }
  }
  if (typeof obj.additions === 'object') {
    for (const prop in obj.additions) {
      definePropertyGetSet(obj.additions[prop], obj)
    }
  }
  if (typeof obj?.render?.legend === 'object') {
    definePropertyGetSet(obj.render.legend)
  }
}

const defineRenders = obj => {
  // NOTE: 此時底下的Property還沒被設定Getters, 要用標準結構取值
  if (typeof obj.render !== 'object') return

  Object.keys(obj.render).forEach(key => {
    if (key === 'info') return

    Object.defineProperty(obj, key, {
      get: function() {
        return obj.render[key]
      }
    })
  })

  if (typeof obj.render.info === 'object') {
    Object.keys(obj.render.info).forEach(key => {
      Object.defineProperty(obj, key, {
        get: function() {
          return obj.render.info[key]
        }
      })
    })
  }
}

const defineChildrenAdditions = obj => {
  // 找出obj底下layout屬於children/additions的Property
  // NOTE: 此時底下的Property還沒被設定Getters, 要用標準結構取值
  Object.defineProperty(obj, 'children', {
    get: function() {
      const entries = Object.keys(obj)
        .filter(key => obj[key]?.render?.layout === 'children')
        .map(key => [key, obj[key]])

      if (!entries.length) return undefined

      const children = Object.fromEntries(entries)

      return children
    }
  })
  Object.defineProperty(obj, 'additions', {
    get: function() {
      const entries = Object.keys(obj)
        .filter(key => obj[key]?.render?.layout === 'additions')
        .map(key => [key, obj[key]])

      if (!entries.length) return undefined

      const additions = Object.fromEntries(entries)

      return additions
    }
  })
}

const defineRootDisabled = (obj, root) => {
  // root一開始為空, 第一個遞迴才把rootObj帶進去
  if (root) {
    Object.defineProperty(obj, 'rootDisabled', {
      configurable: true,
      get: function() {
        if (root.disabled || root.rootDisabled) return true

        if (typeof root.apply !== 'boolean') return false

        return !root.apply
      }
    })

    if (typeof obj.legend === 'object') {
      defineRootDisabled(obj.legend, root)
    }
  }
}

const defineLegendApply = obj => {
  // 將legend的apply綁定成操作fieldset的apply
  Object.defineProperty(obj.legend, 'apply', {
    get: function() {
      return obj.apply
    },
    set: function(apply) {
      obj.apply = apply
    }
  })
}

export const getProp = obj => {
  if (obj?.case) {
    return obj?.cases?.[obj.case]
  }

  return obj
}

export const getPropVal = (obj, dotKey) => {
  if (!obj || !dotKey) return

  const keys = dotKey.split('.')

  let property = obj?.[keys[0]]
  keys.slice(1).forEach(key => {
    if (!property) return

    property = property[key]
  })

  property = getProp(property)

  return property?.value
}

export const readSourceValues = (obj, source) => {
  if (!source) return

  if (
    !Object.getOwnPropertyDescriptor(source, 'children') &&
    !Object.getOwnPropertyDescriptor(source, 'additions')
  ) {
    defineChildrenAdditions(source)
  }

  if (Object.prototype.hasOwnProperty.call(obj, 'value')) {
    obj.value = source.value ?? obj.value
  }

  if (typeof obj.apply === 'boolean') {
    obj.apply = source.apply ?? obj.apply
  }

  if (typeof obj.cases === 'object') {
    obj.case = source.case ?? obj.case
  }

  if (typeof obj.targetOptions === 'object') {
    obj.target = source.target ?? obj.target
  }

  if (typeof obj.unitOptions === 'object') {
    obj.unit = source.unit ?? obj.unit
  }

  // 遞迴obj底下所有prop
  if (typeof obj.children === 'object') {
    for (const prop in obj.children) {
      readSourceValues(obj.children[prop], source.children?.[prop])
    }
  }
  if (typeof obj.cases === 'object' && source.cases) {
    for (const prop in obj.cases) {
      readSourceValues(obj.cases[prop], source.cases?.[prop])
    }
  }
  if (typeof obj.additions === 'object') {
    for (const prop in obj.additions) {
      readSourceValues(obj.additions[prop], source.additions?.[prop])
    }
  }
  if (typeof obj?.render?.legend === 'object') {
    readSourceValues(obj.render.legend, source?.render?.legend)
  }
}
