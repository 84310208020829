import Vue from 'vue'

const setVisibility = (el, binding) => {
  const value = binding.value
  value ? (el.style.visibility = 'visible') : (el.style.visibility = 'hidden')
}

Vue.directive('visible', {
  bind: setVisibility,
  update: setVisibility
})
