export const SETTINGS_KEYS = {
  D3_CENTER: {
    key: 'd3Center',
    defaultValue: []
  },
  SKY_AXIS: {
    key: 'skyAxis',
    defaultValue: '',
    options: [
      {
        label: 'Y軸',
        value: 'y'
      },
      {
        label: 'Z軸',
        value: 'z'
      }
    ]
  },
  PARENT_VTK: {
    key: 'parentVtk',
    defaultValue: null
  },
  BOUNDING_BOX_VTK: {
    key: 'boundingBoxVtk',
    defaultValue: null
  },
  MASSBALANCE: {
    key: 'massbalance',
    defaultValue: {
      inputs: []
    }
  }
}
