import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.commonauth.passwords

export const apiCommonauthPasswords = {
  head(username) {
    const url = `${apiConfig.url}/${username}`

    const withCredentials = checkTokenRequired(apiConfig, 'head')

    const config = {
      withCredentials
    }

    return http.head(url, config)
  },
  post(data) {
    // data = {
    //   password: String,
    //   code: String
    // }

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  }
}
