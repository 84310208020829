import { Tree } from './Tree'

export class FileTree extends Tree {
  constructor(uuid, config) {
    const { ...treeConfig } = config

    super(uuid, treeConfig)

    return this
  }

  // getter/setter

  // methods
}
