import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.project.resourcePermissions
const url = apiConfig.url
export const apiProjectResourcePermissions = {
  get(projectId) {
    if (!projectId) return

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const apiUrl = `${url}/${projectId}`

    const config = {
      withCredentials
    }

    return http.get(apiUrl, config)
  }
}
