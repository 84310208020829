import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.grafana.dashboards
const url = apiConfig.url

export const apiGrafanaDashboards = {
  get(resourceId, projectId, isAll = true) {
    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const keyResourceId = isAll ? 'parent_resource_uuid' : 'resource_uuid'

    const config = {
      withCredentials,
      params: {
        [keyResourceId]: resourceId, // grafanaId || dashboard resource ID
        project_uuid: projectId
      }
    }

    return http.get(url, config)
  },
  post(data) {
    // data = {
    //   dashboard: Object,
    //   parent_resource_id: String, // project's grafanaId
    //   project_id: String
    // }

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  },
  put(data) {
    // data = {
    //   payload: {
    //     dashboard: Object
    //   },
    //   resource_id: String,
    //   project_id: String
    // }

    const withCredentials = checkTokenRequired(apiConfig, 'put')

    const config = {
      withCredentials
    }

    if (data.payload) {
      data.payload.overwrite = true
    }

    return http.put(url, data, config)
  },
  delete(data) {
    // data = {
    //   project_id: String,
    //   resource_id: String
    // }

    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials,
      data
    }

    return http.delete(url, config)
  }
}
