import * as modules from './modules'

import { SIM_MODEL } from '@/models'

import { apiSimulationModels } from '@/api'

import camelize from 'camelize'

const formatModels = models => {
  if (!Array.isArray(models)) {
    return []
  }

  return models.map(model => ({
    ...model,
    text: model.modelName,
    value: model.uuid
  }))
}

const state = () => ({
  isLoadingModels: false,
  model: SIM_MODEL.MODFLOW,
  models: [],
  editModelFileNode: null
})

const getters = {
  model: state => state.models.find(model => model.uuid === state.model),
  modelImages: (_, getters) => {
    if (!Array.isArray(getters.model?.images)) {
      return []
    }

    return getters.model.images.map(image => ({
      ...image,
      text: image.tag,
      value: image.imageName
    }))
  }
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  fetchModels: ({ commit }) => {
    commit('setState', {
      isLoadingModels: true
    })
    return apiSimulationModels.get()
      .then(res => {
        const models = camelize(res.data.data)

        commit('setState', {
          models: formatModels(models)
        })

        return models
      })
      .finally(() => {
        commit('setState', {
          isLoadingModels: false
        })
      })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const simulation = {
  namespaced: true,
  modules,
  getters,
  state,
  mutations,
  actions
}
