import { apiCommonauthTeams } from '@/api'

import { formatMembers } from './teams'

const state = () => ({
  step: 1,
  formData: {
    groupName: '',
    members: []
  },
  currentEditTeam: null, // just for refrence, do not modify content fields
  isSending: false
})

const getters = {
  isEditModel: state => !!state.currentEditTeam
}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },

  setState: ({ commit }, payload) => {
    commit('setState', payload)
  },

  setFormData: ({ commit }, payload) => {
    commit('setFormData', payload)
  },

  edit: ({ commit }, { team } = {}) => {
    if (!team) return

    commit('setState', {
      ...state(),
      formData: {
        groupName: team.groupName,
        members: formatMembers(team.members)
      },
      currentEditTeam: team
    })
  },

  submit: ({ commit, state, getters }) => {
    commit('setState', {
      isSending: true
    })

    const members = state.formData.members.map(m => m.username)

    if (getters.isEditModel) {
      // edit team
      const formData = {
        old_group_name: state.currentEditTeam.groupName,
        new_group_name: state.formData.groupName,
        members
      }

      return apiCommonauthTeams.put(formData).finally(() => {
        commit('setState', {
          isSending: false
        })
      })
    } else {
      // create team
      const formData = {
        group_name: state.formData.groupName,
        members
      }
      return apiCommonauthTeams.post(formData).finally(() => {
        commit('setState', {
          isSending: false
        })
      })
    }
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setFormData: (state, payload) => {
    Object.assign(state.formData, payload)
  }
}

export const form = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
