import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.project.projects
const url = apiConfig.url
export const apiProjectProjects = {
  get(projectId) {
    const apiUrl = projectId ? `${url}/${projectId}` : url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials
    }

    return http.get(apiUrl, config)
  },
  post(data) {
    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }
    return http.post(url, data, config)
  },
  put(projectUuid, data) {
    const apiUrl = `${url}/${projectUuid}`
    const withCredentials = checkTokenRequired(apiConfig, 'put')

    const config = {
      withCredentials
    }
    return http.put(apiUrl, data, config)
  },
  delete(projectUuid, data) {
    const apiUrl = `${url}/${projectUuid}`
    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials,
      data
    }

    return http.delete(apiUrl, config)
  }
}
