// import { createTag } from './tag'
// import { formatTagTemplate } from './tag'

export const createTarget = (alias = '', options = {}) => {
  const {
    measurement = ''
  } = options

  return {
    refId: alias,
    measurement,
    query: '',
    select: [
      [
        {
          params: [''],
          type: 'field'
        },
        {
          params: [],
          type: 'mean'
        }
      ]
    ],
    tags: []
    // tags: [createTag()]
    // groupBy: [
    //   {
    //     params: ['$__interval'],
    //     type: 'time'
    //   },
    //   {
    //     params: ['null'],
    //     type: 'fill'
    //   }
    // ],
    // orderByTime: 'ASC',
    // policy: 'default',
    // queryType: 'randomWalk',
    // resultFormat: 'time_series',
  }
}

export const formatQuery = (
  {
    influxBucketName,
    target,
    useFeatureProperties = false,
    aggregateWindow,
    start = '-20y',
    stop = 'now()'
  }
) => {
  // useFeatureProperties為false或feature的properties(Object)
  const el = '\r\n  '
  const importLib = `import "types"${el}`
  const from = `from(bucket:"${influxBucketName}")${el}`
  const range = `|> range(start:${start}, stop:${stop})${el}`

  // filter tags
  const queryTag = target.tags
    .filter(tag => tag.key)
    .filter(tag => !!useFeatureProperties || !tag.isGeojsonVariable)
    .map(tag => {
      // const tagValue = tag.isGeojsonVariable ? formatTagTemplate(tag) : tag.value
      const tagValue = tag.isGeojsonVariable
        ? useFeatureProperties[tag.key] || ''
        : tag.value

      return `r.${tag.key} ${tag.operator} "${tagValue}"`
    })
    // .map(tag => `r.${tag.key} ${tag.operator} "\${${tag.key}}"`)
    .reduce((acc, tag) => {
      acc += ` and${el}    ${tag}`

      return acc
    }, '')
  // filter field
  const selectField = target?.select?.[0]?.[0]?.params?.[0] || ''
  const queryField = ` and${el}     r._field == ${
    selectField ? `"${selectField}"` : 'r._field'
  }`
  const typesField = ` and${el}     (types.isType(v: r._value, type: "int") or types.isType(v: r._value, type: "float"))`

  const filter = `|> filter(fn:(r) =>${el}      r._measurement == "${target.measurement}" ${queryTag} ${queryField} ${typesField})${el}`

  let aggregateWindowComm = ''
  if (aggregateWindow) {
    aggregateWindowComm = `|> aggregateWindow(${aggregateWindow})${el}`
  }
  // const aggregateWindow = `|> aggregateWindow(column: "_value", every: ${'1w'}, fn: mean, createEmpty: false)`

  return `${importLib} ${from}    ${range}    ${filter}    ${aggregateWindowComm}`
}
