import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.s3.maplayers

export const apiS3Maplayers = {
  get(params) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params
    }

    return http.get(url, config)
  },
  post(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  },
  patch(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'patch')

    const config = {
      withCredentials
    }

    return http.patch(url, data, config)
  },
  delete(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials,
      data
    }

    return http.delete(url, config)
  }
}
