import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.grafana.snapshots
const url = apiConfig.url

export const apiGrafanaSnapshots = {
  post(data) {
    // data = {
    //   dashboard: Object,
    //   from_time: String,
    //   to_time: String,
    //   panel_id: Number
    // }

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  }
}
