<template>
  <v-snackbar
    v-model="snack"
    class="global-snackbar"
    absolute
    app
    :timeout="snackTimeout"
    :color="snackColor"
    :top="y === 'top'"
    :bottom="y === 'bottom'"
    :right="x === 'right'"
    :left="x === 'left'"
  >
    {{ snackText }}
    <template #action="{ attrs }">
      <v-btn
        v-bind="attrs"
        text
        @click="snack = false"
      >
        {{ $t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GlobalSnackbar',

  computed: {
    ...mapState('snackbar', {
      snackColor: state => state.snackColor,
      snackText: state => state.snackText,
      snackTimeout: state => state.snackTimeout,
      x: state => state.x,
      y: state => state.y
    }),

    snack: {
      get() {
        return this?.$store?.state?.snackbar?.snack
      },
      set(newVal) {
        if (newVal === true) return

        this.$store.dispatch('snackbar/close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.global-snackbar {
  z-index: 1000000;
}
</style>
