import { FileNode } from '@/models'

export const iterateFileNode2ResourceUuid = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] instanceof FileNode) {
      obj[key] = obj[key].uuid
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      iterateFileNode2ResourceUuid(obj[key])
    }
  })
}
