// Property = {
//   value: String,
//   valueType: String,
//   unit: String, // optional
//   apply: Boolean, // optional
//   target: String, // optional, 提供SameAs, EqualAs功能
//   targetOptions: Array<Object::<v-select items>>, // optional, 提供Select指定target
//   apply: Boolean, // optional
//   render: Render,
//   [<property_key>]: Property,
//   ...
// }

// Render = {
//   layout: 'children', |  'additions', // optional
//   tag: 'fieldset', // optional
//   legend: Property, // optional, use with tag is fieldset
//   class: Object::<v-bind:class>,
//   disabled: Boolean, // optional
//   show: Boolean, // option, false時隱藏Tab, 只提供info有component的Tab使用
//   showTabHeader: Boolean, // option, false時隱藏TabHeader, 只提供info有component的Tab使用
//   info: {
//     text: String, // optional
//     buttonText: String // optional
//     component: String // component name, NOTE: render成v-tab-item一定要指定
//   }
// }

import { Parameters } from './parameters'
// polygon properties constructor
function LineStringParameters(source) {
  this.Head = {
    render: {
      showTabHeader: false,
      component: 'Head'
    },
    case: 'NonSpecified',
    cases: {
      NonSpecified: {
        render: {
          info: {
            text: 'Non-Specified'
          }
        }
      },
      ConstantHead: {
        value: '',
        unit: 'm',
        render: {
          info: {
            text: 'Constant Head'
          }
        }
      },
      SameAs: {
        target: 'Elevation',
        render: {
          info: {
            text: 'Head = Elevation (e.g. Water Table)'
          }
        }
      },
      VariablePrescribedHead: {
        render: {
          disabled: true,
          info: {
            text: 'Variable Prescribed Head'
          }
        },
        More: {
          render: {
            layout: 'additions',
            disabled: true,
            class: {
              'ml-4': true
            },
            info: {
              buttonText: '...'
            }
          }
        }
      },
      River: {
        render: {
          disabled: true,
          info: {
            text: 'River'
          }
        },
        More: {
          render: {
            layout: 'additions',
            disabled: true,
            class: {
              'ml-4': true
            },
            info: {
              buttonText: '...'
            }
          }
        }
      },
      Drain: {
        render: {
          disabled: true,
          info: {
            text: 'Drain'
          }
        },
        More: {
          render: {
            layout: 'additions',
            disabled: true,
            class: {
              'ml-4': true
            },
            info: {
              buttonText: '...'
            }
          }
        }
      }
    }
  }

  // 對所有props做額外Get/Set設定, 讀回原本設定的values
  // children/additions/攤平render,render.info的getters (undefined|object)
  // 尋找需要設定legend apply getter/setter的props
  // 為所有prop根據prop所有爺/父層的apply或disabled做rootDisabled的getter

  Parameters.call(this, source)
}
LineStringParameters.prototype = Object.create(Parameters.prototype)

LineStringParameters.prototype.constructor = LineStringParameters
export { LineStringParameters }
