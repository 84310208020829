import Axios from 'axios'
import settings from '@/assets/js/settings.js'

import i18n from '@/i18n'

import {
  addHeaderAuthorization,
  handlerTokenError
} from './utils'

const baseURL = settings.API_ENDPOINT
Axios.defaults.timeout = 0

// 攔截 request
const reqHandler = config => {
  if (config.withCredentials) {
    addHeaderAuthorization(config)
  }

  return config
}
const reqErrorHandler = err => {
  return Promise.reject(err)
}

// 攔截 response
const resSuccessHandler = res => {
  const status = res.status

  // token 過期，移除token
  if (status === 202) {
    // 需要token的api才做導回登入頁
    if (res.request.withCredentials) {
      handlerTokenError()
    }

    return Promise.reject(new Error(res?.data?.message || i18n.t('api_errors.unauthorized')))
  }

  return res
}
const resErrorHandler = error => {
  const errCode = error?.response?.status

  if (errCode === 401) {
    // 需要token的api才做導回登入頁
    if (error.request.withCredentials) {
      handlerTokenError()
    }
  }

  const data = error?.response?.data
  const message = data?.message

  if (message) {
    return Promise.reject(new Error(message, { cause: error }))
  }

  return Promise.reject(error)
}

const instances = {
  http: Axios.create({ baseURL })
}

Object.keys(instances).forEach(key => {
  instances[key].interceptors.request.use(reqHandler, reqErrorHandler)
  instances[key].interceptors.response.use(resSuccessHandler, resErrorHandler)
})

export const { http } = instances
