import { definePropertyGetSet, readSourceValues } from './utils'

export function Parameters(source) {
  // 對所有props做額外Get/Set設定, 讀回原本設定的values
  // children/additions/攤平render,render.info的getters (undefined|object)
  // 尋找需要設定legend apply getter/setter的props
  // 為所有prop根據prop所有爺/父層的apply或disabled做rootDisabled的getter

  Object.keys(this).forEach(tabKey => {
    if (tabKey === 'render' || tabKey === 'case') return

    const tabProp = this[tabKey]

    definePropertyGetSet(tabProp)

    if (source && tabProp.cases) {
      readSourceValues(tabProp, source?.[tabKey])
    }

    Object.keys(tabProp).forEach(propKey => {
      if (propKey === 'render' || propKey === 'case') return

      const prop = tabProp[propKey]
      definePropertyGetSet(prop, tabProp)

      if (source) {
        readSourceValues(prop, source[tabKey]?.[propKey])
      }
    })
  })
}
