import camelize from 'camelize'

import { form } from './database-form'

import { apiInfluxMeasurements } from '@/api'

const formatDatabases = databases => {
  if (!Array.isArray(databases)) return []

  return databases.map(db => {
    return {
      resourceUuid: db.resource.uuid,
      name: db.measurementName
    }
  })
}

const state = () => ({
  databases: [],
  isLoadingDatabases: false
})

const getters = {}

const actions = {
  init: ({ commit, dispatch }) => {
    dispatch('form/init')
    commit('setState', state())
  },

  fetchDatabases: async ({ rootState, commit, dispatch }) => {
    commit('setState', {
      isLoadingDatabases: true
    })

    if (!rootState.influxBucketId) {
      const error = await dispatch('fetchResourceIds', null, {
        root: true
      }).catch(error => error)

      if (!rootState.influxBucketId) {
        commit('setState', {
          isLoadingDatabases: false
        })
        return Promise.reject(error)
      }
    }

    return apiInfluxMeasurements.get(rootState.influxBucketId)
      .then(res => {
        const databases = formatDatabases(camelize(res?.data?.data))

        commit('setState', {
          databases
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingDatabases: false
        })
      })
  },
  deleteDatabase: (_, database) => {
    const resourceUuid = database.resourceUuid

    return apiInfluxMeasurements.delete({
      resource_uuid: resourceUuid
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const databases = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    form
  }
}
