import { Node } from '@/models'
import { setProperties, isNumber } from '@/models/utils'

export class Tree {
  uuid
  root=null

  constructor(uuid, { root } = {}) {
    root && this.setRoot(root)

    setProperties(this, {
      uuid
    })

    return this
  }

  // getter/setter

  // methods
  setRoot(root) {
    /**
     * 允許root為null
     * @param {Node} [root=null]
     * @returns self
    **/
    if (root && !(root instanceof Node)) {
      console.debug('Cannot set root which is not Node instance')
      return this
    }

    setProperties(this, {
      root: root ?? null
    })

    return this
  }

  static addNode(parent, node, beforeNode) {
    /**
     *  @param {Node} parent
     *  @param {Node} node
     *  @param {Node} [beforeNode=null] - 指定位置做insert，不指定就是append
     *  @returns self
    **/

    if (!(parent instanceof Node)) {
      console.debug('Cannot add node which target parent is not Node instance')

      return this
    }
    if (!(node instanceof Node)) {
      console.debug('Cannot add node which is not Node instance')

      return this
    }
    if (beforeNode && beforeNode.parent !== parent) {
      // 無效的beforeNode, 當作沒有beforeNode繼續處理
      console.debug('Only beforeNode of same parent as target parent is valid')
    }

    const hasBeforeNode = beforeNode instanceof Node && beforeNode.parent === parent

    if (
      hasBeforeNode &&
      isNumber(beforeNode.index) &&
      beforeNode.index > -1
    ) {
      const index = beforeNode.index

      parent.children.splice(index, 0, node)
    } else {
      // parent.children.splice(parent.children.length, 0, node)
      parent.children.push(node)
    }

    node.setParent(parent)

    return this
  }

  static removeNode(node) {
    /**
     *  @param {Node} node - 要被移除的node
     *  @returns self
    **/

    if (!(node instanceof Node)) {
      console.debug('Cannot remove node which is not Node instance')
      return this
    }

    // 移除root的情況
    if (node === this.root) {
      return this.setRoot(null)
    }

    // 除了tree.root外, 無法移除沒有parent的node
    if (!node.parent) {
      console.debug('Cannot remove node which w/o parent')
      return this
    }

    node.parent.children.splice(node.index, 1).shift()

    node.setParent(null)

    return this
  }

  addNode(...args) {
    return Tree.addNode.call(this, ...args)
  }

  removeNode(...args) {
    return Tree.removeNode.call(this, ...args)
  }

  moveNode(parent, node, beforeNode) {
    /**
     *  @param {Node} parent - 移動目標的parent
     *  @param {Node} node - 被移動的node
     *  @param {Node} [beforeNode=null] - 沒有beforeNode就是移到目標parent的TOP
     *  @returns self
    **/

    if (!(parent instanceof Node)) {
      console.debug('Cannot move node which parent in not instanceof Node')
      return this
    }
    if (parent.level !== node.level - 1) {
      console.debug('Can only move at the same level of node')
      return this
    }

    Tree.removeNode(node)

    Tree.addNode(parent, node, beforeNode)

    return this
  }

  getLeavesDF(root, targetClass = Node, filterFn) {
    /**
     *  Get leaves in this by breadth first
     *  time complexity: O(N)
     *  ref: https://danmartensen.svbtle.com/converting-a-tree-to-a-list-in-javascript
     *  @param {string} root - 起始節點
     *  @param {string} [targetClass=Node] - 指定leaf必須為某class才加進來
     *  @returns array
    **/

    if (!(root instanceof Node)) {
      console.debug('Cannot getLeavesDF w/o give a root')
      return []
    }

    const stack = [root]
    const array = []
    const hashMap = {} // 預防重複
    const target = targetClass
    const hasFilterFn = typeof filterFn === 'function'

    while (stack.length) {
      const node = stack.pop()

      if (!node.hasChildren) {
        if (node instanceof target) {
          if (!hasFilterFn || Boolean(filterFn(node))) {
            visitNode(node, hashMap, array)
          }
        }
      } else {
        if (Array.isArray(node.children)) {
          // stack.push(...node.children.slice().reverse())
          for (let i = node.children.length - 1; i >= 0; i--) {
            stack.push(node.children[i])
          }
        }
      }
    }

    return array
  }

  findNodeBF(root, target, key = 'uuid') {
    /**
     *  Find node in this by breadth first
     *  time complexity: O(N)
     *  @param {string} root - 目標的起始節點
     *  @param {string} target - 目標的值
     *  @param {string} [key='uuid'] - 判斷目標值的key
     *  @returns Node | null
    **/

    if (!(root instanceof Node)) {
      console.debug('Cannot findNodeBF w/o give a root')
      return this
    }

    const stack = [root]
    while (stack.length) {
      const node = stack.shift()

      if (node[key] === target) {
        return node
      }

      if (Array.isArray(node.children)) {
        stack.push(...node.children)
      }
    }

    return null
  }

  findNodeDF(root, target, key = 'uuid') {
    /**
     *  Find node in this by breadth first
     *  time complexity: O(N)
     *  @param {string} root - 目標的起始節點
     *  @param {string} target - 目標的值
     *  @param {string} [key='uuid'] - 判斷目標值的key
     *  @returns Node | null
    **/

    if (!(root instanceof Node)) {
      console.debug('Cannot findNodeBF w/o give a root')
      return this
    }

    const stack = [root]
    while (stack.length) {
      const node = stack.pop()

      if (node[key] === target) {
        return node
      }

      if (Array.isArray(node.children)) {
        // stack.push(...node.children.slice().reverse())
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push(node.children[i])
        }
      }
    }

    return null
  }

  getOrAddChildren({ parent, node, key = 'uuid', beforeNode } = {}) {
    if (!Array.isArray(parent?.children)) {
      console.debug('Cannot getOrAddChildren w/o children of parent')
      return null
    }

    const targetNode = parent.children.find(child => child[key] === node[key])

    if (targetNode) {
      return targetNode
    }

    this.addNode(parent, node, beforeNode)

    return node
  }
}

const visitNode = (node, hashMap, array) => {
  if (!hashMap[node.uuid]) {
    hashMap[node.uuid] = true
    array.push(node)
  }
}
