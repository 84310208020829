import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.s3.stagingfile.promotion
export const apiS3StagingfilePromotion = {
  post(data) {
    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  }
}
