import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.commonauth.teams

export const apiCommonauthTeams = {
  get(groupName = '') {
    const url = `${apiConfig.url}/${groupName}`

    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials
    }

    return http.get(url, config)
  },
  post(data) {
    // data = {
    //   group_name: 'aaa',
    //   members: ['aa@bb.com']
    // }

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  },
  put(data) {
    // data = {
    //   old_group_name: 'MIN',  // 原本的團隊名稱
    //   new_group_name: 'MIN2', // 新的團隊名稱，optional
    //   members: [              // 不需修改的話就空陣列，不然就全新完整的名單
    //     'aaaaaaa@gmailgmail.com',
    //     'bbbbbbbbbb@gmail.com',
    //     'cc@gmailgmail.com',
    //     'dd@gmailgmailgmailgmail.com'
    //   ]
    // }

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'put')

    const config = {
      withCredentials
    }

    return http.put(url, data, config)
  },
  delete(groupName) {
    if (!groupName) return

    const url = apiConfig.url

    const withCredentials = checkTokenRequired(apiConfig, 'delete')

    const config = {
      withCredentials,
      data: {
        group_name: groupName
      }
    }

    return http.delete(url, config)
  }
}
