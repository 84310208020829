<template>
  <v-app class="ease-app">
    <router-view />

    <GlobalSnackbar />
  </v-app>
</template>

<script>
import GlobalSnackbar from '@/components/GlobalSnackbar'

export default {
  name: 'App',

  components: {
    GlobalSnackbar
  },

  watch: {
    '$route.params.lang': {
      handler(newLang, oldLang) {
        // 只做第一次的trigger
        if (newLang && !oldLang) {
          this.changeLanguage(newLang)
        }
      },
      immediate: true
    }
  },

  methods: {
    changeLanguage(lang) {
      this.$store.dispatch('changeLanguage', { lang })
    }
  }
}
</script>

<style lang="scss" scoped>
.ease-app.v-application {
  &.theme--light {
    background-color: $color-bg-ease-grey;
  }
}
</style>
