import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.grafana.datasource
const url = apiConfig.url

export const apiGrafanaDatasource = {
  get(datasourceName) {
    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params: {
        datasource_name: datasourceName
      }
    }

    return http.get(url, config)
  }
}
