import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.influx.buckets
const url = apiConfig.url
export const apiInfluxBuckets = {
  get(influxBucketId, projectId) {
    const withCredentials = checkTokenRequired(apiConfig, 'get')

    const config = {
      withCredentials,
      params: {
        resource_uuid: influxBucketId,
        project_uuid: projectId
      }
    }

    return http.get(url, config)
  }
}
