// Property = {
//   value: String,
//   valueType: String,
//   unit: String, // optional
//   apply: Boolean, // optional
//   target: String, // optional, 提供SameAs, EqualAs功能
//   targetOptions: Array<Object::<v-select items>>, // optional, 提供Select指定target
//   apply: Boolean, // optional
//   render: Render,
//   [<property_key>]: Property,
//   ...
// }

// Render = {
//   layout: 'children', |  'additions', // optional
//   tag: 'fieldset', // optional
//   legend: Property, // optional, use with tag is fieldset
//   class: Object::<v-bind:class>,
//   disabled: Boolean, // optional
//   show: Boolean, // option, false時隱藏Tab, 只提供info有component的Tab使用
//   showTabHeader: Boolean, // option, false時隱藏TabHeader, 只提供info有component的Tab使用
//   info: {
//     text: String, // optional
//     buttonText: String // optional
//     component: String // component name, NOTE: render成v-tab-item一定要指定
//   }
// }

import { Parameters } from './parameters'
// polygon properties constructor
function PointParameters(source) {
  this.Well = {
    render: {
      info: {
        text: 'Well',
        component: 'Well'
      }
    },
    TopElevation: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Top Elevation'
            }
          }
        }
      },
      case: 'Constant',
      cases: {
        Constant: {
          value: '10.0',
          valueType: 'string',
          unit: 'm',
          render: {
            info: {
              text: 'Constant'
            }
          }
        }
      }
    },
    BottomElevation: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Bottom Elevation'
            }
          }
        }
      },
      case: 'Constant',
      cases: {
        Constant: {
          value: '-50',
          valueType: 'string',
          unit: 'm',
          render: {
            info: {
              text: 'Constant'
            }
          }
        }
      }
    },
    WellType: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Well Type'
            }
          }
        }
      },
      case: 'Pumping',
      cases: {
        render: {
          class: {
            'align-self-start': true,
            'pt-2': true
          }
        },
        Pumping: {
          render: {
            info: {
              text: 'Pumping'
            }
          }
        },
        Injection: {
          render: {
            info: {
              text: 'Injection'
            }
          }
        },
        None: {
          render: {
            info: {
              text: 'None'
            }
          }
        }
      }
    },
    PrescribedHead: {
      render: {
        class: {
          'flex-lg-nowrap': true
        },
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Prescribed Head'
            }
          }
        }
      },
      case: 'Constant',
      cases: {
        Constant: {
          value: '-50.0',
          valueType: 'string',
          unit: 'm^3/day',
          render: {
            info: {
              text: 'Constant'
            }
          },
          Transient: {
            apply: false,
            value: [],
            render: {
              layout: 'children',
              disabled: true,
              info: {
                buttonText: 'Transient'
              }
            }
          }
        },
        EqualsTo: {
          value: '20',
          valueType: 'string',
          unit: '% of Q in',
          target: '',
          targetOptions: [
            {
              text: 'Well 1003',
              value: '1003'
            }
          ],
          render: {
            class: {
              'flex-lg-nowrap': true
            },
            disabled: true,
            info: {
              text: 'Equals to'
            }
          }
        },
        None: {
          render: {
            disabled: false,
            info: {
              text: 'None'
            }
          }
        }
      },
      Factor: {
        value: '1',
        valueType: 'string',
        render: {
          layout: 'additions',
          info: {
            text: 'Factor'
          }
        }
      },
      HeadCorrection: {
        apply: false,
        render: {
          layout: 'additions',
          info: {
            text: 'Head Correction'
          }
        }
      }
    },
    Concentration: {
      render: {
        class: {
          'flex-lg-nowrap': true
        },
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Concentration'
            }
          }
        }
      },
      case: 'Constant',
      cases: {
        Constant: {
          value: '-50.0',
          valueType: 'string',
          unit: 'm^3/day',
          render: {
            info: {
              text: 'Constant'
            }
          },
          Transient: {
            apply: false,
            value: [],
            render: {
              layout: 'children',
              disabled: true,
              info: {
                buttonText: 'Transient'
              }
            }
          }
        },
        EqualsTo: {
          value: '20',
          valueType: 'string',
          unit: '% of Q in',
          target: '',
          targetOptions: [
            {
              text: 'Well 1003',
              value: '1003'
            }
          ],
          render: {
            class: {
              'flex-lg-nowrap': true
            },
            disabled: true,
            info: {
              text: 'Equals to'
            }
          }
        },
        None: {
          render: {
            disabled: false,
            info: {
              text: 'None'
            }
          }
        }
      },
      Factor: {
        value: '1',
        valueType: 'string',
        render: {
          layout: 'additions',
          info: {
            text: 'Factor'
          }
        }
      },
      HeadCorrection: {
        apply: false,
        render: {
          layout: 'additions',
          info: {
            text: 'Head Correction'
          }
        }
      }
    },
    MonitoringWell: {
      render: {
        tag: 'fieldset',
        legend: {
          render: {
            info: {
              text: 'Monitoring Well'
            }
          }
        }
      },
      MonitoringHeadAndConcentration: {
        apply: false,
        render: {
          layout: 'children',
          info: {
            text: 'Monitoring Head and Concentration'
          }
        }
      }
    }
  }

  // 對所有props做額外Get/Set設定, 讀回原本設定的values
  // children/additions/攤平render,render.info的getters (undefined|object)
  // 尋找需要設定legend apply getter/setter的props
  // 為所有prop根據prop所有爺/父層的apply或disabled做rootDisabled的getter
  Parameters.call(this, source)

  // 額外條件的disabled
  const self = this.Well;

  // MonitoringWell就不必設WellType相關, WellType None就不必設P/C
  ['WellType', 'PrescribedHead', 'Concentration'].forEach(key => {
    Object.defineProperty(self[key], 'rootDisabled', {
      get: function() {
        return (
          (key !== 'WellType' && self.WellType.case === 'None') ||
          self.MonitoringWell.MonitoringHeadAndConcentration.apply ||
          self.rootDisabled ||
          self.disabled
        )
      }
    })
  });
  // P/C None就不必設F/H, F/H也跟隨P/C的disabled狀態
  ['PrescribedHead', 'Concentration'].forEach(parentKey => {
    ['Factor', 'HeadCorrection'].forEach(key => {
      Object.defineProperty(self[parentKey][key], 'rootDisabled', {
        get: function() {
          return (
            self[parentKey].case === 'None' ||
            self[parentKey].rootDisabled ||
            self[parentKey].disabled ||
            self[parentKey].apply === false
          )
        }
      })
    })
  })
}

PointParameters.prototype = Object.create(Parameters.prototype)

PointParameters.prototype.constructor = PointParameters

export { PointParameters }
