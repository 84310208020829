export const apiInfo = {
  commonauth: {
    tokens: {
      url: '/api/commonauth/tokens',
      tokenRequiredMethods: ['put', 'delete']
    },
    passwords: {
      url: '/api/commonauth/passwords',
      tokenRequiredMethods: []
    },
    users: {
      url: '/api/commonauth/users',
      tokenRequiredMethods: ['head', 'get', 'delete']
    },
    admin: {
      users: {
        url: '/api/commonauth/admin/users',
        tokenRequiredMethods: ['delete', 'post', 'get']
      }
    },
    teams: {
      url: '/api/commonauth/teams',
      tokenRequiredMethods: ['*']
    }
  },
  s3: {
    bucket: {
      url: '/api/s3/buckets',
      tokenRequiredMethods: ['get']
    },
    folders: {
      url: '/api/s3/folders',
      tokenRequiredMethods: ['*']
    },
    mapsets: {
      url: '/api/s3/mapsets',
      tokenRequiredMethods: ['*']
    },
    maplayers: {
      url: '/api/s3/maplayers',
      tokenRequiredMethods: ['*']
    },
    maplayer: {
      data: {
        url: '/api/s3/maplayer/data',
        tokenRequiredMethods: ['*']
      },
      metadata: {
        url: '/api/s3/maplayer/metadata',
        tokenRequiredMethods: ['*']
      }
    },
    stagingfiles: {
      url: '/api/s3/stagingfiles',
      tokenRequiredMethods: ['*']
    },
    stagingfile: {
      data: {
        url: '/api/s3/stagingfile/data',
        tokenRequiredMethods: ['*']
      },
      promotion: {
        url: '/api/s3/stagingfile/promotion',
        tokenRequiredMethods: ['*']
      }
    }
  },
  project: {
    projects: {
      url: '/api/project/projects',
      tokenRequiredMethods: ['*']
    },
    resources: {
      url: '/api/project/resources',
      tokenRequiredMethods: ['*']
    },
    resourcePermissions: {
      url: '/api/project/resource_permissions',
      tokenRequiredMethods: ['*']
    }
  },
  influx: {
    buckets: {
      url: '/api/influx/buckets',
      tokenRequiredMethods: ['*']
    },
    measurements: {
      url: '/api/influx/measurements',
      tokenRequiredMethods: ['*']
    }
  },
  grafana: {
    grafanas: {
      url: '/api/grafana/grafanas',
      tokenRequiredMethods: ['*']
    },
    dashboards: {
      url: '/api/grafana/dashboards',
      tokenRequiredMethods: ['*']
    },
    snapshots: {
      url: '/api/grafana/snapshots',
      tokenRequiredMethods: ['*']
    },
    queries: {
      url: '/api/grafana/queries',
      tokenRequiredMethods: ['*']
    },
    datasource: {
      url: '/api/grafana/datasources',
      tokenRequiredMethods: ['*']
    }
  },
  simulation: {
    simulationModels: {
      url: '/api/simulation/simulation-models',
      tokenRequiredMethods: ['*']
    }
  }
}

export const checkTokenRequired = (apiConfig, method) => {
  const methods = apiConfig.tokenRequiredMethods

  if (!Array.isArray(methods)) return true

  if (methods.includes('*')) return true

  return methods.includes(method)
}
