import { StagingFile } from '@/models'
import { SETTINGS_KEYS } from '@/models/utils'
// import { SETTINGS_KEYS, setProperties } from '@/models/utils'

import {
  WsSlice
} from '@/websocket'

const { PARENT_VTK } = SETTINGS_KEYS

const state = () => ({
  isSlicing: false
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  start: async ({ rootState, commit, dispatch }, { project, glbLayerNode, origin, merge = false } = {}) => {
    const wsSlice = new WsSlice()

    glbLayerNode.setProperties({
      isSlicing: true
    })
    const resetStaging = rootState.postprocess.resetStaging
    let glbStagingFileUuid = glbLayerNode.stagingFile?.uuid
    const countVtkStagingFiles = merge ? 1 : 3
    const vtkStagingFileUuids = Array(countVtkStagingFiles).fill(null)
    if (!resetStaging) {
      // 創glb staging file
      if (!glbStagingFileUuid) {
        glbStagingFileUuid = await dispatch('postprocess/createStagingFile', {
          project,
          glbLayerNode
        }, { root: true })

        if (glbStagingFileUuid instanceof Error || !glbStagingFileUuid) {
          commit('setState', {
            isSlicing: false
          })

          return Promise.reject(glbStagingFileUuid || new Error('未知的錯誤'))
        }
      }
      // 創vtk staging file給slice平面使用
      const error = await Promise.all(
        vtkStagingFileUuids.map((_, iVtk) => dispatch('postprocess/createStagingFile', {
          project,
          glbLayerNode
        }, { root: true }).then(stagingFileUuid => { vtkStagingFileUuids[iVtk] = stagingFileUuid }))
      )
        .then(() => {})
        .catch(error => {
          commit('setState', {
            isSlicing: false
          })

          return Promise.reject(error || new Error('未知的錯誤'))
        })
      if (error instanceof Error) {
        return Promise.reject(error)
      }
    }

    const settings = glbLayerNode.stagingFile
      ? glbLayerNode.stagingFile.settings
      : glbLayerNode.settings

    const sourceMesh = glbLayerNode.sourceMesh
    const plotSetting = settings.plot.legends.find(legend => legend.scalarsName === sourceMesh.settings.activeScalarsName)

    // const activeScalarsName = mesh.settings.activeScalarsName

    wsSlice.start({
      resetStaging,
      createStagingFile: () => dispatch('postprocess/createStagingFile', {
        project,
        glbLayerNode
      }, { root: true }),
      project_uuid: project.uuid,
      parent_resource_uuid: glbLayerNode.parent.uuid, // mapset resource uuid
      vtk_mesh_pairs: [
        {
          mesh_uuid: sourceMesh.name,
          vtk_resource_uuid: sourceMesh?.settings?.[PARENT_VTK.key]
        },
        ...vtkStagingFileUuids
          .filter(Boolean)
          .map(vtkStagingFileUuid => ({
            mesh_uuid: null,
            vtk_resource_uuid: vtkStagingFileUuid
          }))
      ],
      vtk_resource_uuid: sourceMesh?.settings?.[PARENT_VTK.key],
      glb_resource_uuid: glbLayerNode.uuid,
      glb_staging_file_resource_uuid: glbStagingFileUuid,
      origin,
      countVtkStagingFiles,
      merge,
      active_scalars_name: plotSetting.scalarsName,
      colormap_name: plotSetting.colormapName,
      colormap_level: plotSetting.colormapLevel,
      range_min: plotSetting.rangeMin,
      range_max: plotSetting.rangeMax
    })

    // reset過就不要再reset
    wsSlice.$on(wsSlice.EVENT.STAGING_RESET, () => {
      commit('postprocess/setState', {
        resetStaging: false
      }, { root: true })
    })

    return new Promise((resolve, reject) => {
      wsSlice.$on(wsSlice.TASK.SUCCESS, data => {
        const stagingFileUuid = data?.resource?.uuid
        if (!stagingFileUuid) {
          commit('setState', {
            isSlicing: false
          })
          return reject(new Error('暫存檔建立失敗'))
        }

        dispatch('postprocess/fetchStagingFileContent', {
          project,
          stagingFileUuid
        }, { root: true })
          .then(res => {
            const settings = glbLayerNode.stagingFile
              ? glbLayerNode.stagingFile.settings
              : glbLayerNode.settings

            const stagingBlob = new Blob([res.data])
            const stagingFile = new StagingFile(
              stagingFileUuid,
              {
                name: glbLayerNode.name,
                fileContent: stagingBlob,
                settings
              }
            )

            return glbLayerNode
              .setStagingFile(stagingFile)
              .importMesh(stagingBlob, [sourceMesh])
              .then(importedMeshes => {
                const newSourceMesh = importedMeshes.find(importedMeshe => importedMeshe.id === sourceMesh.id) || sourceMesh
                glbLayerNode.toggleMeshVisible(newSourceMesh, false)

                glbLayerNode.stagingFile.setProperties({
                  settings: {
                    ...settings,
                    meshes: [
                      ...settings.meshes,
                      ...data.plotSetting.vtkMeshPairs
                        .filter(newMesh => newMesh.meshUuid !== newSourceMesh.id)
                        .map(newMesh => ({
                          name: newMesh.meshUuid,
                          [PARENT_VTK.key]: newMesh.vtkResourceUuid,
                          activeScalarsName: plotSetting.scalarsName,
                          legends: newMesh?.scalars || {}
                        }))
                    ]
                  }
                })
              })
          })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            glbLayerNode.setProperties({
              isSlicing: false
            })
          })
      })

      wsSlice.$on(wsSlice.EVENT.ERROR, error => {
        glbLayerNode.setProperties({
          isSlicing: false
        })
        reject(error)
      })
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const slice = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
