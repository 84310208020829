import Vue from 'vue'
import camelize from 'camelize'
import snakeize from 'snakeize'
import settings from '@/assets/js/settings.js'

import EventWebSocket from '@/websocket/EventWebSocket'

const wsBaseUrl = settings.WS_ENDPOINT
const url = `${wsBaseUrl}/ws/simulation/simulations`

const EVENT_MAP = {
  OPEN: 'open',
  START: 'start_job',
  STARTED: 'job_started',
  CHECK_STATUS: 'check_status',
  STATUS_CHECKED: 'status_checked',
  END: 'end_job',
  ENDED: 'job_ended',
  ERROR: 'error'
}

const TASK_STATUS_MAP = {
  SUCCESS: 'Succeeded',
  FAILURE: 'Failed'
}

const data = () => ({
  ws: null,
  data: null, // {
  //   simulation_name: 'ddd',
  //   pod_data: {
  //     apiVersion: 'v1',
  //     kind: 'Pod',
  //     metadata: {
  //       name: 'pod-example'
  //     },
  //     spec: {
  //       containers: [
  //         {
  //           name: 'simulation',
  //           image: 'registry.digitalocean.com/registry-repository/ease-x.com/simulation/pyvista-interpolating:v2022.03.15.07'
  //         }
  //       ]
  //     }
  //   },
  //   project_uuid: '{{project_uuid}}',
  //   input: {
  //     geojson_list: [
  //       {
  //         geo_file: 'd967f23e-e47a-4066-bece-39c33a572c76',
  //         param_file: '44574638-adab-497f-a1f4-4f8a22827c6e'
  //       },
  //       {
  //         geo_file: '97709484-2a12-40eb-8184-9b98a5854d5a',
  //         param_file: ''
  //       }
  //     ]
  //   }
  // }
  taskUuid: null,
  EVENT: EVENT_MAP,
  TASK: TASK_STATUS_MAP,
  isRunning: false,
  delayCheck: 500, // ms,
  errorMessage: ''
})

const computed = {}

const beforeDestroy = function() {
  if (this.ws?.readyState === 1) {
    this.close()
  }
}

const methods = {
  send(eventName, data) {
    if (this.ws?.readyState !== EventWebSocket.OPEN) {
      return
    }

    this.ws.send(eventName, data)
  },
  start(data) {
    this.isRunning = true
    try {
      const image = data.image

      this.data = {
        simulation_name: 'massbalance slice',
        pod_data: {
          apiVersion: 'v1',
          kind: 'Pod',
          metadata: {
            name: 'pod-example'
          },
          spec: {
            containers: [
              {
                name: 'simulation',
                image
              }
            ]
          }
        },
        ...snakeize(data)
      }

      delete this.data.image
    } catch (error) {
      this.isRunning = false

      this.onError(error)

      throw error
    }

    this.connect()
  },
  connect() {
    this.ws = new EventWebSocket(url)

    this.ws.addEventListener(this.EVENT.OPEN, this.onOpen)

    this.ws.addEventListener('message', e => {
      const versionUuid = e?.data?.version_uuid
      if (versionUuid) {
        this.data.version_uuid = versionUuid
      }
    })

    // Trigger check task after job started
    this.ws.addEventListener(this.EVENT.STARTED, this.onStarted)
    // Task status handler
    this.ws.addEventListener(this.EVENT.STATUS_CHECKED, this.onStatusChecked)
    // Job ended handler
    this.ws.addEventListener(this.EVENT.ENDED, this.onEnded)

    this.ws.addEventListener(this.EVENT.ERROR, this.onError)
    this.ws.onclose = this.onClose
  },
  sendStartJob() {
    this.send(this.EVENT.START, this.data)
  },
  sendCheckStatus() {
    this.send(this.EVENT.CHECK_STATUS, {
      redis_data_id: this.taskUuid,
      version_uuid: this.data.version_uuid
    })
  },
  sendEndJob() {
    this.send(this.EVENT.END, {
      redis_data_id: this.taskUuid,
      version_uuid: this.data.version_uuid
    })
  },
  onOpen(e) {
    if (!this.data) {
      return
    }

    if (this.taskUuid) {
      this.sendCheckStatus()

      return
    }

    // create a job task
    this.sendStartJob()
  },
  onStarted(e) {
    this.$emit(this.EVENT.STARTED, e.data)

    // this.taskUuid = e.data.task_uuid
    this.taskUuid = e.data.redis_data_id

    // Trigger check process
    this.sendCheckStatus()
  },
  onStatusChecked(e) {
    this.$emit(this.EVENT.STATUS_CHECKED, camelize(e.data))

    const status = e.data.status

    // Success case
    if (status === this.TASK.SUCCESS) {
      this.$emit(this.TASK.SUCCESS, camelize(e.data))

      // this.sendEndJob()
      return
    }

    // Failure case
    if (status === this.TASK.FAILURE) {
      this.onError(new Error('發生未知的錯誤'))
      this.sendEndJob()
      return
    }

    // Pedding case
    setTimeout(() => {
      this.sendCheckStatus()
    }, this.delayCheck)
  },
  onEnded(e) {
    this.isRunning = false

    this.$emit(this.EVENT.ENDED, e.data)

    this.close()
  },
  onError(e) {
    this.isRunning = false
    const error = e instanceof Error ? e : new Error('Websocket連線發生錯誤')
    this.$emit(this.EVENT.ERROR, error)
  },
  onClose(e) {
    this.isRunning = false
    if (e.code !== 1000 && e.code) {
      console.debug(e)
      this.$emit(this.EVENT.ERROR, new Error(`連線發生錯誤，錯誤代碼:${e.code}`))
    }
  },
  close() {
    this.ws.close()

    this.$destroy()
  }
}

export class WsMassbalanceSlice extends Vue {
  constructor() {
    super({
      data,
      computed,
      beforeDestroy,
      methods
    })
  }
}
