import { http } from '@/api/requests'
import { apiInfo, checkTokenRequired } from '@/api/config'

const apiConfig = apiInfo.grafana.queries
const url = apiConfig.url

export const apiGrafanaQueries = {
  post(data) {
    // data = {
    //   queries: Object,
    //   from: String,
    //   to: String,
    //   range: Object
    // }

    const withCredentials = checkTokenRequired(apiConfig, 'post')

    const config = {
      withCredentials
    }

    return http.post(url, data, config)
  }
}
