import { iterateFileNode2ResourceUuid } from './utils'
import { cloneDeep } from 'lodash'

import { WsFemwater } from '@/websocket'

const state = () => ({
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  start: ({ rootGetters }, { project, taskData } = {}) => {
    const wsFemwater = new WsFemwater()

    return new Promise((resolve, reject) => {
      wsFemwater.$on(wsFemwater.TASK.SUCCESS, data => {
        resolve(data)
      })

      wsFemwater.$on(wsFemwater.EVENT.ERROR, error => {
        reject(error)
      })

      const [image] = rootGetters['simulation/model']?.images?.slice?.(-1) || []

      const copyTaskData = cloneDeep(taskData)
      iterateFileNode2ResourceUuid(copyTaskData)

      wsFemwater.start({
        image: image.imageName,
        task_data: copyTaskData,
        project_uuid: project.uuid
      })
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const femwater = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
