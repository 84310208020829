import { StagingFile } from '@/models'
import { SETTINGS_KEYS, setProperties } from '@/models/utils'
import { formatLegend } from '@/store/modules/files/files'

import {
  WsPlot
} from '@/websocket'

const { PARENT_VTK, BOUNDING_BOX_VTK } = SETTINGS_KEYS

const state = () => ({
  isPloting: false,
  colormapNames: [
    'jet', 'RdYlGn', 'rainbow', 'bwr', 'coolwarm', 'seismic', 'twilight',
    'gray', 'binary',
    'spring', 'summer', 'autumn', 'winter'
  ]
})

const getters = {}

const actions = {
  init: ({ commit }) => {
    commit('setState', state())
  },
  start: async ({ rootState, commit, dispatch }, { project, glbLayerNode, plotSettings } = {}) => {
    const wsPlot = new WsPlot()

    commit('setState', {
      isPloting: true
    })

    const resetStaging = rootState.postprocess.resetStaging
    let stagingFileUuid = glbLayerNode.stagingFile?.uuid
    if (!resetStaging && !stagingFileUuid) {
      // 發api創staging file
      stagingFileUuid = await dispatch('postprocess/createStagingFile', {
        project,
        glbLayerNode
      }, { root: true })

      if (stagingFileUuid instanceof Error || !stagingFileUuid) {
        commit('setState', {
          isPloting: false
        })

        return Promise.reject(stagingFileUuid || new Error('未知的錯誤'))
      }
    }

    const scalarsName = plotSettings.scalarsName
    const meshes = glbLayerNode.meshes.filter(mesh => mesh?.settings?.activeScalarsName === scalarsName)
    const layerNode = glbLayerNode.stagingFile || glbLayerNode
    const newLegends = layerNode.settings.plot.legends.map(legend => {
      if (legend.scalarsName === scalarsName) {
        return {
          ...legend,
          ...plotSettings
        }
      }

      return legend
    })

    if (!meshes.length) {
      layerNode.readSettings({
        plot: {
          legends: newLegends
        }
      })
      commit('setState', {
        isPloting: false
      })

      return
    }

    const boundingBoxVtkResourceUuid = layerNode.settings[BOUNDING_BOX_VTK.key] ||
    layerNode?.sourceMesh?.settings?.[PARENT_VTK.key] ||
    layerNode.settings[PARENT_VTK.key]

    wsPlot.start({
      resetStaging,
      createStagingFile: () => dispatch('postprocess/createStagingFile', {
        project,
        glbLayerNode
      }, { root: true }),
      project_uuid: project.uuid,
      parent_resource_uuid: glbLayerNode.parent.uuid, // mapset resource uuid
      vtk_mesh_pairs: meshes.map(mesh => ({
        mesh_uuid: mesh.name,
        vtk_resource_uuid: mesh?.settings?.[PARENT_VTK.key]
      })),
      bounding_box_vtk_resource_uuid: meshes.some(mesh => mesh?.settings?.parentVtk === boundingBoxVtkResourceUuid)
        ? null
        : boundingBoxVtkResourceUuid,
      // bounding_box_vtk_resource_uuid: null,
      glb_resource_uuid: glbLayerNode.uuid,
      glb_staging_file_resource_uuid: stagingFileUuid,
      active_scalars_name: plotSettings.scalarsName,
      colormap_name: plotSettings.colormapName,
      colormap_level: plotSettings.colormapLevel,
      range_min: plotSettings.rangeMin,
      range_max: plotSettings.rangeMax
    })

    // reset過就不要再reset
    wsPlot.$on(wsPlot.EVENT.STAGING_RESET, () => {
      commit('postprocess/setState', {
        resetStaging: false
      }, { root: true })
    })

    return new Promise((resolve, reject) => {
      wsPlot.$on(wsPlot.TASK.SUCCESS, data => {
        const plotSettings = data?.plotSettings?.[0]
        const newLegend = formatLegend(scalarsName, plotSettings)
        const legends = layerNode.settings.plot.legends.map(legend => {
          if (legend.scalarsName === scalarsName) {
            setProperties(legend, {
              isPloting: false
            })
            newLegend.isPloting = legend.isPloting
            newLegend.visible = true
            return newLegend
          }

          return legend
        })

        const stagingFileUuid = data?.resource?.uuid
        if (!stagingFileUuid) {
          commit('setState', {
            isPloting: false
          })
          return reject(new Error('暫存檔建立失敗'))
        }

        dispatch('postprocess/fetchStagingFileContent', {
          project,
          stagingFileUuid
        }, { root: true })
          .then(res => {
            const settings = glbLayerNode.stagingFile
              ? glbLayerNode.stagingFile.settings
              : glbLayerNode.settings

            const stagingBlob = new Blob([res.data])
            const stagingFile = new StagingFile(
              stagingFileUuid,
              {
                name: glbLayerNode.name,
                fileContent: stagingBlob,
                settings: {
                  ...settings,
                  plot: {
                    ...(settings?.plot || {}),
                    ...data.settings,
                    legends
                  }
                }
              }
            )

            return glbLayerNode
              .setStagingFile(stagingFile)
              .importMesh(stagingBlob, meshes)
          })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            commit('setState', {
              isPloting: false
            })
          })
      })

      wsPlot.$on(wsPlot.EVENT.ERROR, error => {
        commit('setState', {
          isPloting: false
        })
        reject(error)
      })
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const plot = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
